import React, { useRef } from 'react';
import { InputLabel } from '../../Shared';

const TimeDuration = ({ duration, minDuration, cusDuration, onPress }) => (
  <button
    type="button"
    className={
      'font-sf-regular rounded flex flex-col px-8 justify-center items-center focus:outline-none appearance-none mt-2 md:mt-0'
    }
    style={{
      borderWidth: duration === minDuration && !cusDuration ? 2 : 1,
      borderColor: duration === minDuration && !cusDuration ? '#042E60' : '#CCCC',
      opacity: duration === minDuration && !cusDuration ? 0.9 : 0.5,
    }}
    onClick={() => onPress(duration)}>
    <span className="text-primary-color">{duration}</span>
    <span className="text-primary-color text-xs">min</span>
  </button>
);

const DurationPicker = ({ minDuration, cusDuration, setCusDuration, setMinDuration, ...props }) => {
  let custom_min_ref = useRef(null);
  return (
    <div className='my-2'>
      <InputLabel label="Duration" />
      <div className="flex justify-between flex-col md:flex-row">
        {['30', '45', '60'].map((dur, index) => (
          <TimeDuration
            key={index}
            duration={dur}
            cusDuration={cusDuration}
            minDuration={minDuration}
            onPress={duration => {
              setMinDuration(duration);
              setCusDuration(null);
            }}
          />
        ))}

        <div
          onClick={() => {
            custom_min_ref.current.focus();
            setCusDuration('');
            setMinDuration(null);
          }}
          className={
            'flex font-sf-regular flex-col w-auto px-3 rounded justify-center items-center focus:outline-none appearance-none text-primary-color mt-2 md:mt-0'
          }
          style={{
            borderWidth: cusDuration || cusDuration === '' ? 2 : 1,
            borderColor: cusDuration || cusDuration === '' ? '#042E60' : '#CCCC',
            opacity: cusDuration || cusDuration === '' ? 1 : 0.5,
          }}>
          <div className="flex">
            <input
              ref={custom_min_ref}
              type="number"
              className="flex w-16 text-center bg-transparent font-sf-regular text-xs text-primary-color focus:outline-none"
              onChange={e => {
                if (e.target.value < 0) return;
                else {
                  setCusDuration(e.target.value);
                }
              }}
              value={cusDuration}
              placeholder="-"
            />
          </div>
          <span className="text-primary-color text-xs">custom min</span>
        </div>
      </div>
    </div>
  );
};

export default DurationPicker;
