import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import TimePicker from '../Athlete/TimePicker/TimePicker';
import { days, trainingOption, userChoice } from '../../constants';
import {
  formatDate,
  isFutureTime,
  isDateAfter,
  isTimeAfter,
  notify,
  isTimeDurationEligible,
  getTrainingColor,
  getTrainingType,
} from '../../utils/utilities';
import {
  SelectionButton,
  CustomDatePicker,
  TrainingTags,
  ModalBackground,
  InputLabel,
} from '../Shared';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

function EditAvailability(props) {
  const [fromTime, setFrom] = useState('8:00:00');
  const [toTime, setTo] = useState('10:15:00');
  const [weekDays, setWeekDays] = useState(days);
  const [daysToPass, setDaysToPass] = useState([]);
  const [availabilityChoice, setAvailabilityChoice] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [singleSelection, setSingleSelection] = useState('');
  const [trainingType, setTrainingType] = useState(null);
  const [excludedTrainings, setExcluded] = useState([]);
  const [allDaysSelect, setAllDaysSelect] = useState(false);

  const {
    getBulkTrainings,
    trainingsPagy,
    trainings,
    updateFrom,
    updateTo,
    training_type,
    refreshModal,
    updateRangeFrom,
    updateRangeTo,
    isVisible,
    providedServices,
    closeModal,
    buttonLoading,
  } = props;

  useEffect(() => {
    setFrom(updateFrom ? `${moment(updateFrom, 'hh:mm a').format('HH:mm')}:00` : '8:00:00');
    setTo(updateTo ? `${moment(updateTo, 'hh:mm a').format('HH:mm')}:00` : '10:15:00');
    setDaysToPass([]);
    setExcluded([]);
    checkAndSetDays();
    setTrainingType(
      training_type === 'virtual'
        ? trainingOption.VIRTUAL
        : training_type === 'individual'
        ? trainingOption.INDIVIDUAL
        : trainingOption.GROUP
    );
  }, [updateFrom, updateTo, updateRangeFrom, updateRangeTo, refreshModal, props.days]);

  const handleDaySelection = passed => {
    const mapped = weekDays.map(item =>
      passed.day === 'All'
        ? { ...item, checked: passed.checked ? false : true }
        : item.day === passed.day
        ? { ...item, checked: !item.checked }
        : item
    );

    setWeekDays(mapped);

    let filtered = mapped
      .filter(item => {
        return item.checked && item.value !== -1;
      })
      .map(item => item.value);

    setDaysToPass(filtered);
  };

  const renderDays = () => {
    return weekDays.map((item, index) => (
      <SelectionButton
        key={index}
        text={item.day}
        checked={item.checked}
        onClick={() => {
          handleDaySelection(item);
        }}
        value={item.day}
        days={true}
      />
    ));
  };

  const checkAndSetDays = () => {
    let copyDays = [];
    copyDays = days;

    let updatedDays = copyDays.map(item =>
      props.days.includes(item.value.toString()) ? { ...item, checked: true } : item
    );

    setAllDaysSelect(props.days.length === 7);
    setWeekDays(updatedDays);
  };

  const handleOnChange = (type, date) => {
    type === 'start' ? setStartDate(date) : setEndDate(date);
  };

  const handleSelectSingle = date => {
    setSingleSelection(date);
  };

  const applyChanges = () => {
    const { availId, selected_trainings, days, apply } = props;
    const repetition_from =
      startDate === ''
        ? singleSelection === ''
          ? formatDate(updateRangeFrom)
          : moment(singleSelection).format('YYY-MM-DD')
        : moment(startDate).format('YYYY-MM-DD');
    const repetition_frequency = availabilityChoice
      ? availabilityChoice === userChoice.ONETIME
        ? 'daily'
        : 'weekly'
      : updateRangeFrom === updateRangeTo
      ? 'daily'
      : 'weekly';

    if (!fromTime || !toTime) notify('Enter Start and End times.');
    else if (!isTimeAfter) notify('End time should be after from time.');
    else if (respectiveTrainings.filter(item => item.checked).length === 0)
      notify('Please select the trainings to tag!');
    else if (!isTimeDurationEligible(fromTime, toTime, respectiveTrainings))
      notify('Please increase your time duration.');
    else {
      if (!isFutureTime(repetition_from, fromTime)) {
        return;
      }

      const payload = {
        availability: {
          from_time: fromTime,
          to_time: toTime,
          repetition_frequency,
          repetition_from,
          training_type: getTrainingType(trainingType),
          training_color: getTrainingColor(trainingType),
          selected_trainings:
            respectiveTrainings.length === 0
              ? selected_trainings
              : respectiveTrainings.filter(item => item.checked).map(item => item.id),
        },
      };

      if (
        availabilityChoice
          ? availabilityChoice === userChoice.ONETIME
          : updateRangeFrom === updateRangeTo
      ) {
        payload.availability.repetition_from =
          singleSelection === ''
            ? formatDate(updateRangeFrom)
            : moment(singleSelection).format('YYYY-MM-DD');
        payload.availability.repetition_until =
          singleSelection === ''
            ? formatDate(updateRangeFrom)
            : moment(singleSelection).format('YYYY-MM-DD');
        payload.availability.days = [];
      } else if (
        availabilityChoice
          ? availabilityChoice === userChoice.REPEAT
          : updateRangeFrom !== updateRangeTo
      ) {
        payload.availability.days = daysToPass.length === 0 ? days : daysToPass;
        payload.availability.repetition_until =
          endDate === '' ? formatDate(updateRangeTo) : moment(endDate).format('YYYY-MM-DD');
      }

      if (
        (availabilityChoice
          ? availabilityChoice === userChoice.REPEAT
          : updateRangeFrom !== updateRangeTo) &&
        !isDateAfter(payload.availability.repetition_from, payload.availability.repetition_until)
      ) {
        notify('End date should be after start date.');
        return;
      }
      apply(payload, 'update', availId);
    }
  };

  //Methods to handle trainings selection with respect to the selected training type.
  useMemo(() => {
    let excluded =
      trainingType &&
      trainings
        .filter(({ attributes }) => {
          return (
            attributes.training_type === trainingType.toLowerCase() &&
            !props.selected_trainings.includes(attributes.id)
          );
        })
        .map(({ attributes }) => attributes.id);

    if (trainingType && training_type === trainingType.toLowerCase())
      setExcluded(...excludedTrainings, excluded);
  }, [trainingType]);

  const getSelectedTags = () => {
    return trainings
      .filter(({ attributes }) => {
        return (
          attributes.training_type === trainingType.toLowerCase() &&
          attributes.status === 'published' &&
          !attributes.deleted &&
          !attributes.package
        );
      })
      .map(({ attributes }) => {
        const checked = excludedTrainings.includes(attributes.id) ? false : true;

        return {
          name: attributes.name,
          id: attributes.id,
          checked,
          training_type: attributes.training_type,
          duration: attributes.duration,
        };
      });
  };

  const respectiveTrainings = useMemo(() => {
    return (
      trainingType &&
      trainings
        .filter(({ attributes }) => {
          return (
            attributes.training_type === trainingType.toLowerCase() &&
            attributes.status === 'published' &&
            !attributes.deleted &&
            !attributes.package
          );
        })
        .map(({ attributes: { name, id, training_type, duration } }) => {
          const checked = excludedTrainings.includes(id) ? false : true;
          return { name, id, checked, training_type, duration };
        })
    );
  }, [excludedTrainings, trainingType]);

  //Method to handle checkboxes with each of the training
  const handleTagSelection = (checked, item) => {
    let update = [];

    if (checked) {
      update = [...excludedTrainings, item.id];
      setExcluded(update);
    } else {
      if (item.training_type === 'group') {
        //To enable toggle buttons behaviour for group trainings
        update = [];

        respectiveTrainings.map(single => {
          if (single.id === item.id) {
            update = update.filter(id => id !== single.id);
          } else {
            update = [...update, single.id];
          }
        });

        setExcluded(update);
      } else {
        update = excludedTrainings.filter(id => id !== item.id);
        setExcluded(update);
      }
    }
  };
  /////

  return (
    <div
      className={
        isVisible ? `fixed pin px-4 pb-4 inset-0 flex items-center justify-center z-50` : 'hidden'
      }>
      <ModalBackground />

      <div
        className="bg-white pb-20 shadow-xl transform transition-all w-full border-gray-700 border-2 overflow-scroll"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style={{ width: '500px', maxHeight: window.innerHeight / 1.3, borderRadius: '10px' }}>
        <div className="p-2 max-h-screen">
          <div className="flex-col bg-white px-4 flex py-4 cursor-pointer minimum-height">
            <h4 className="text-sm font-sf-regular text-black text-center">
              Update Your Availability
            </h4>

            <div className="border-b border-gray-300 my-2" />

            <div className="my-3 focus:outline-none content-start justify-start flex-col">
              <InputLabel label="Option" />

              <div className="flex justify-between w-full">
                <SelectionButton
                  text={userChoice.ONETIME}
                  onClick={setAvailabilityChoice}
                  value={userChoice.ONETIME}
                  checked={
                    availabilityChoice
                      ? availabilityChoice === userChoice.ONETIME
                      : updateRangeFrom === updateRangeTo
                  }
                />

                <SelectionButton
                  text={userChoice.REPEAT}
                  onClick={setAvailabilityChoice}
                  value={userChoice.REPEAT}
                  checked={
                    availabilityChoice
                      ? availabilityChoice === userChoice.REPEAT
                      : updateRangeFrom !== updateRangeTo
                  }
                />
              </div>

              <InputLabel label="Type" />

              <div className="flex justify-between w-full">
                {providedServices.includes(trainingOption.VIRTUAL.toLowerCase()) && (
                  <SelectionButton
                    text={trainingOption.VIRTUAL}
                    checked={trainingType === trainingOption.VIRTUAL}
                    value={trainingOption.VIRTUAL}
                    onClick={value => {
                      setExcluded([]);
                      setTrainingType(value);
                    }}
                  />
                )}

                {providedServices.includes(trainingOption.INDIVIDUAL.toLowerCase()) && (
                  <SelectionButton
                    text={trainingOption.INDIVIDUAL}
                    checked={trainingType === trainingOption.INDIVIDUAL}
                    value={trainingOption.INDIVIDUAL}
                    onClick={value => {
                      setExcluded([]);
                      setTrainingType(value);
                    }}
                  />
                )}

                {providedServices.includes(trainingOption.GROUP.toLowerCase()) && (
                  <SelectionButton
                    text={trainingOption.GROUP}
                    checked={trainingType === trainingOption.GROUP}
                    value={trainingOption.GROUP}
                    onClick={value => {
                      setExcluded([]);
                      setTrainingType(value);
                    }}
                  />
                )}
              </div>

              {trainingType && (
                <div className="w-full mt-2">
                  <InputLabel label="Training Options Included" />

                  <div>
                    <TrainingTags
                      options={
                        training_type === trainingType.toLowerCase()
                          ? getSelectedTags()
                          : respectiveTrainings
                      }
                      handleTagSelection={handleTagSelection}
                    />
                  </div>

                  {trainingsPagy.next !== null && (
                    <div className="cursor-pointer" onClick={getBulkTrainings}>
                      <span className="text-blue-500 hover:text-black text-xs">Load more</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {(availabilityChoice
              ? availabilityChoice === userChoice.REPEAT
              : updateRangeFrom !== updateRangeTo) && (
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <InputLabel label="Days Available" />

                  <label className="inline-flex items-center">
                    <span className="primary-text-regular normal-case">Select all</span>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setAllDaysSelect(!allDaysSelect);
                        handleDaySelection({ day: 'All', checked: allDaysSelect, value: -1 });
                      }}
                      className="form-checkbox h-3 w-3 ml-1"
                      checked={allDaysSelect}
                    />
                  </label>
                </div>

                <div className="flex flex-col  overflow-x-scroll no-scrollbar">
                  <div className="flex enable-wrap"> {renderDays()} </div>
                </div>
              </div>
            )}

            {(availabilityChoice
              ? availabilityChoice === userChoice.ONETIME
              : updateRangeFrom === updateRangeTo) &&
              updateRangeFrom && (
                <div className="flex flex-col justify-start content-start w-full">
                  <CustomDatePicker
                    title="Select Date"
                    placeholder="Start Date"
                    handleOnChange={handleSelectSingle}
                    selected={
                      singleSelection
                        ? singleSelection
                        : new Date(formatDate(updateRangeFrom).replace(/-/g, '/'))
                    }
                  />
                </div>
              )}

            {(availabilityChoice
              ? availabilityChoice === userChoice.REPEAT
              : updateRangeFrom !== updateRangeTo) && (
              <div className="flex flex-col justify-start content-start mt-2">
                <div className="flex justify-between flex-wrap">
                  <div className="w-full md:w-1/2 pr-2">
                    <CustomDatePicker
                      placeholder="Start Date"
                      title="Start date"
                      handleOnChange={date => {
                        handleOnChange('start', date);
                        handleOnChange('end', date);
                      }}
                      selected={
                        startDate
                          ? startDate
                          : new Date(formatDate(updateRangeFrom).replace(/-/g, '/'))
                      }
                    />
                  </div>

                  <div className="w-full md:w-1/2 pr-2">
                    <CustomDatePicker
                      placeholder="End Date"
                      title="End date"
                      handleOnChange={date => {
                        handleOnChange('end', date);
                      }}
                      selected={
                        endDate ? endDate : new Date(formatDate(updateRangeTo).replace(/-/g, '/'))
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col justify-start content-start mt-2">
              <div className="flex justify-between w-full items-center content-center">
                <div className="mr-2 w-1/2">
                  <InputLabel label="Start time" />

                  <TimePicker
                    value={fromTime}
                    onChange={date => setFrom(moment(date.value, 'h:mmA').format('HH:mm') + ':00')}
                    name="From"
                  />
                </div>

                <div className="w-1/2">
                  <InputLabel label="End time" />

                  <TimePicker
                    value={toTime}
                    onChange={date => setTo(moment(date.value, 'h:mmA').format('HH:mm') + ':00')}
                    name="To"
                  />
                </div>
              </div>
            </div>

            <button
              onClick={closeModal}
              className="hover:bg-secondary text-blue-900 text-sm hover:text-blue-500 py-2 px-2 rounded absolute top-0 right-0 font-bold focus:outline-none">
              X
            </button>

            <div className="flex font-sf-regular flex-row justify-end mt-4 right-0 bg-white">
              <button
                onClick={applyChanges}
                className="bg-blue-900 hover:bg-blue-800 text-white flex text-sm py-2 px-5 border border-blue-900 ml-2 rounded">
                {updateFrom ? 'Update Availability' : 'Apply'}
                <Loader
                  visible={buttonLoading}
                  type="ThreeDots"
                  color="#fff"
                  className="ml-2"
                  height={20}
                  width={20}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAvailability;
