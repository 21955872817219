import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const SlugChangeInfoModal = ({onReject, onAccept, onDissmis, text, acceptText, rejectText}) => {
    return (
      <Modal show={true} onHide={() => onDissmis()} style={{zIndex: '9999'}}>
          <Modal.Header closeButton>
            <Modal.Title>Heads up!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p dangerouslySetInnerHTML={{ __html: text}}></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => onReject()}> { rejectText ? rejectText : 'Reject' }</Button>
            <Button variant="primary" onClick={() => onAccept()}> { acceptText ? acceptText : 'Accept' } </Button>
          </Modal.Footer>
      </Modal> 
    );
  }

  export default SlugChangeInfoModal;
