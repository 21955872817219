import React from 'react';
import { ModalBackground, CreateTraining } from '../../Shared';

function NewTraining({ closePopup, ...props }) {
  return (
    <div className="fixed pin pb-4 inset-0 inset-x-0 flex items-center justify-center z-10">
      <ModalBackground />

      <div
        className="bg-white overflow-scroll max-h-screen shadow-xl transform transition-all w-full border-gray-700 border"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style={{ width: '500px', height: window.innerHeight / 1.1, borderRadius: '10px' }}>
        <CreateTraining {...props} closePopup={closePopup} />
      </div>
    </div>
  );
}

export default NewTraining;
