import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class Search extends Component {
  render () {
    const {
      searchText,
      onChange,
    } = this.props;

    return (
      <div
        className='search-container font-sf-regular flex flex-row w-full md:w-1/4 px-2 rounded-md items-center m-1'
        style={ { backgroundColor: '#F5F6FA' } }>
        <FontAwesomeIcon icon={ faSearch } className='gray-icon' />
        <input
          className='border-none focus:outline-none font-thin appearance-none text-xs w-full py-2 px-3 text-primary-color bg-transparent'
          type='text'
          name='search'
          value={searchText}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default Search;
