import React, {Fragment, useState} from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { athleteTypes, customStyles } from '../../../constants';
import { InputLabel, OnboardingAction, GooglePlacesInput } from '../../Shared';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import SelectSearch from "react-select-search";
import qs from "qs";
import {client} from "../../../store";
import api from "../../../store/api";
import {deserializeData} from "../../../utils/utilities";

function TrainerInfo({
  handleOnChange,
  sportId,
  school,
  college,
  getSchools,
  getColleges,
  sports,
  type,
  club,
  currentLocation,
  setCurrentLocation,
  setCurrentLocationData,
  hometownLocation,
  setHomeTownLocation,
  setHomeTownLocationData,
  history,
  about,
  jerseyNumber,
  positionFirst,
  positionSecond,
  positionThird,
  getSportPositions,
  sportPositions,
  resetPositions,
  timeZone,
}) {
    const [availableColleges, setAvailableColleges] = useState([]);
    const [availableSchools, setAvailableSchools] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);

    const [schoolId, setSchoolId] = useState(school);
    const [collegeId, setCollegeId] = useState(college);
    const [groupId, setGroupId] = useState(college);

    const searchSchools = (query) => {
        if (!query) {
            return availableSchools;
        }

        return getSchools({ q: { nickname_or_city_start: query } })
            .then((result) => {
                const data = result.map((c) => ({
                    value: c.value,
                    name: c.label
                }))
                setAvailableSchools(data);

                return data;
            });
    }

    const searchColleges = (query) => {
        if (!query) {
            return availableColleges;
        }

        return getColleges({ q: { nickname_or_city_start: query } })
            .then((result) => {
                const data = result.map((c) => ({
                    value: c.value,
                    name: c.label
                }));
                setAvailableColleges(data)

                return data;
            })
    }

  const searchGroups = (query) => {
    if (!query) {
      return availableGroups;
    }

    return new Promise((resolve, reject) => {
      let paramsString = qs.stringify({ q: query });
      client().get(`${api.getGroups()}?${paramsString}`)
        .then(({ data }) => {
          return deserializeData(data);
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => resolve([]))
    })
    .then((result) => {
      const data = result.map((c) => ({
        value: c.id,
        name: c.name
      }));
      const exists = !!data.find((g) => g.name.toLocaleLowerCase() === query.toLocaleLowerCase());
      if (exists === false) {
        data.unshift({ value: query, name: query});
      }

      setAvailableGroups(data)
      return data;
    })
  }

    const onSchoolSelected = (id) => {
        handleOnChange('school', id);
        setSchoolId(id);
    }

    const onCollegeSelected = (id) => {
        handleOnChange('college', id);
        setCollegeId(id);
    }

    const onGroupSelected = (id) => {
      handleOnChange('group', id);
      setGroupId(id);
    }

    const renderGroupOption = (props, option, snapshot, className) => {
      let name = option.name;
      if (isNaN(option.value)) {
        name = 'Create new group: ' + name;
      }
      return (
        <button {...props} className={className} type="button">
          <span>{name}</span>
        </button>
      );
    }

    const onLocationChanged = ({ asString, data }) => {
        setCurrentLocation(asString);
        setCurrentLocationData(data);
    }

    const onHomeTownChanged = ({ asString, data }) => {
        setHomeTownLocationData(data)
        setHomeTownLocation(asString);
    }


  return (
    <Fragment>
      <div className="text-left mb-2">
        <small style={{ fontSize: 15 }} className="text-black font-sf-semibold">
          Teams
        </small>
      </div>

      <div className="container-wrap">
        <div className="container-half md:pr-1">
          <div className="text-left small">
            <InputLabel label="High School" color="black" />

              <SelectSearch
                  options={availableSchools}
                  onChange={onSchoolSelected}
                  value={schoolId}
                  debounce={500}
                  getOptions={searchSchools}
                  search
                  placeholder="Start typing to search highschools"
              />
          </div>
        </div>

        <div className="container-half md:mt-0 margin-top-mob">
          <div className="text-left small">
            <InputLabel label="College" color="black" />

              <SelectSearch
                  options={availableColleges}
                  onChange={onCollegeSelected}
                  value={collegeId}
                  debounce={500}
                  getOptions={searchColleges}
                  search
                  placeholder="Start typing to search colleges"
              />
          </div>
        </div>
      </div>

      <div className="w-full text-left margin-top-mob">
        <InputLabel label="Club" color="black" />

        <Form.Control
          type="text"
          className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
          onChange={e => handleOnChange('club', e.target.value)}
          value={club}
          placeholder="Enter Club"
        />
      </div>

      <div className="w-full text-left margin-top-mob">
        <InputLabel label="Company / Group" color="black" />

        <SelectSearch
          options={availableGroups}
          onChange={onGroupSelected}
          value={groupId}
          getOptions={searchGroups}
          renderOption={renderGroupOption}
          debounce={500}
          search
          placeholder="Start typing to search groups"
        />
      </div>

      <div className="text-left my-2 mt-3">
        <small style={{ fontSize: 15 }} className="text-black font-sf-semibold">
          Details
        </small>
      </div>

      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-2/5 text-left">
          <InputLabel label="Select Sport" color="black" />

          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            className="primary-text-regular"
            placeholder="Select sport"
            value={sportId}
            isSearchable
            onChange={selectedOption => {
              resetPositions();
              handleOnChange('sportId', selectedOption);
              getSportPositions(selectedOption.value);
            }}
            options={sports}
            styles={customStyles}
          />
        </div>

        <div className="w-full md:w-2/5 text-left md:px-1 margin-top-mob">
          <InputLabel label="Trainer Type" color="black" />

          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            className="primary-text-regular"
            placeholder="Select type"
            value={type}
            isSearchable={false}
            onChange={selectedOption => handleOnChange('type', selectedOption)}
            options={athleteTypes}
            styles={customStyles}
          />
        </div>

        <div className="w-full md:w-1/5 text-left margin-top-mob">
          <InputLabel label="Jersey Number" color="black" />

          <Form.Control
            type="number"
            className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
            onChange={e => handleOnChange('jerseyNumber', e.target.value)}
            value={jerseyNumber}
          />
        </div>
      </div>

      <div className="margin-top-mob" />

      <InputLabel label="Position" color="black" />
      <div className="flex justify-between position-col-for-mobile">
        <div className={`w-1/2 w-full-mobile ${sportId === null && 'cursor-not-allowed'}`}>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            maxMenuHeight={180}
            className="primary-text-regular"
            isSearchable={false}
            value={positionFirst}
            isDisabled={sportId === null}
            styles={customStyles}
            placeholder="Position"
            onChange={selectedOption => handleOnChange('positionFirst', selectedOption)}
            options={sportPositions}
          />
        </div>

        <div className={`w-1/2 w-full-mobile md:px-1 ${sportId === null && 'cursor-not-allowed'}`}>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            maxMenuHeight={180}
            className="primary-text-regular"
            isSearchable={false}
            value={positionSecond}
            isDisabled={sportId === null}
            styles={customStyles}
            placeholder="Position 2"
            onChange={selectedOption => handleOnChange('positionSecond', selectedOption)}
            options={sportPositions}
          />
        </div>

        <div className={`w-1/2 w-full-mobile ${sportId === null && 'cursor-not-allowed'}`}>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            maxMenuHeight={180}
            className="primary-text-regular"
            isSearchable={false}
            value={positionThird}
            isDisabled={sportId === null}
            styles={customStyles}
            placeholder="Position 3"
            onChange={selectedOption => handleOnChange('positionThird', selectedOption)}
            options={sportPositions}
          />
        </div>
      </div>

      <div className="text-left my-2 mt-3">
        <small style={{ fontSize: 15 }} className="medium-black">
          Current City
        </small>
      </div>

      <div className="w-full margin-top-mob">
        <GooglePlacesInput
          value={currentLocation}
          onChange={onLocationChanged}
        />
      </div>

      <div className="text-left my-2 mt-3">
        <small style={{ fontSize: 15 }} className="medium-black">
          Hometown City
        </small>
      </div>

      <div className="w-full">
        <GooglePlacesInput
          value={hometownLocation}
          onChange={onHomeTownChanged}
        />
      </div>

      <div className="w-full mt-3">
        <InputLabel label="Time Zone" color="black" />

        <TimezonePicker
          className="w-full"
          absolute={false}
          value={timeZone}
          placeholder="Select timezone..."
          onChange={timeZone => handleOnChange('timeZone', timeZone)}
        />
      </div>

      <div className="w-full mt-2">
        <InputLabel label="About" color="black" />
        <textarea
          rows={3}
          className="text-input focus:border-primary-color"
          value={about}
          onChange={e => handleOnChange('about', e.target.value)}
        />
      </div>

      <OnboardingAction
        hideSignIn
        navigateLogin={() => {
          history.push('/athlete/login');
        }}
      />
    </Fragment>
  );
}

export default TrainerInfo;
