import React from 'react';
import { Form } from 'react-bootstrap';
import { SearchLocationInput, InputLabel } from '../../Shared';

const ServiceLocation = ({
  locationValue,
  addressValue,
  hideRemove,
  onUpdateLocations,
  id,
  allLocations,
  removeLocation,
  totalCharacters,
  withBorder
}) => {
  const onChange = (tag, value) => {
    onUpdateLocations(id, tag, value);
  };

  const allowRemoval = (hideRemove && allLocations && allLocations.length > 1) || !hideRemove;

  return (
    <div className={withBorder ? "p-1 m-1 dashed-border" : ""}>
      <div className="w-full flex flex-col">
        <div className="flex justify-between">
          <InputLabel label={`Training Location (${totalCharacters}/35 characters)`} color={totalCharacters === 35 && 'red'} />

          { allowRemoval &&
            <small className="medium-black cursor-pointer mx-1" onClick={() => removeLocation(id)}>
              x
            </small>
          }
        </div>

        <Form.Control
          type="text"
          maxLength={35}
          className="font-sf-regular bg-transparent text-black text-xs"
          onChange={e => onChange('location', e.target.value)}
          value={locationValue}
          placeholder="i.e. City Field"
          required={true}
        />
      </div>

      <div className="w-full mt-0 md:mt-1 flex flex-col items-start">
        <InputLabel label="Address" />

        <SearchLocationInput
          value={addressValue}
          required={true}
          setLoc={loc => {
            onChange('address', loc);
          }}
          setParseSearch={search => { }}
        />
      </div>
    </div>
  );
};

export default ServiceLocation;
