import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCaretDown } from '@fortawesome/free-solid-svg-icons';

function SettingIcon(props) {
  const [active, setActive] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={e => {
            e.stopPropagation();
            setActive(!active);
          }}
          className="flex items-center focus:outline-none hover:bg-primary-color p-2">
          <FontAwesomeIcon icon={faCog} size="1x" color="#737373" />

          <FontAwesomeIcon icon={faCaretDown} size="xs" className="ml-1" color="#737373" />
        </button>
      </div>

      {active && (
        <div className="origin-top-right absolute right-0 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs px-3 py-2">
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingIcon;
