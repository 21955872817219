import {Button, Modal, Table} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {BookingsActions} from "../../store/actions";
import {confirmAllPayments} from "../../store/Bookings/actions";
import {formatDate, formatTime, notify} from "../../utils/utilities";
import ButtonLoader from "react-loader-spinner";
import Loader from "./Loader";

const connectState = state => ({
  trainings: state.Service.service.get('providedServices'),
  currentUser: state.User.current.get('currentUser'),
});

const enhancer = connect(connectState, { ...BookingsActions });

function ConfirmAllPaymentsModal(props) {
  const [data, setData] = useState(null);
  const [confirming, setConfirming] = useState(false);

  const userData = props.currentUser && props.currentUser.attributes;
  const { trainingId, onHide, trainings } = props;
  const { getAwaitingPayments } = props;
  const training = trainings.find((t) => +t.id === +trainingId).attributes;


  const hasBankAccount = Boolean(userData.account_id);

  useEffect(() => {
    loadAwaitingPayments();
  }, [trainingId])

  const loadAwaitingPayments = () => {
    getAwaitingPayments(trainingId)
      .then((data) => {
        const awaitingPayments = data.map((item) => {
          const booking = item.bookings[0];
          return {
            isPackage: booking.package,
            package_start: booking.package_start,
            package_end: booking.package_end,
            date: booking.booking_date,
            fromTime: booking.from_time,
            toTime: booking.to_time,
            email: booking.client.email,
            fullname: booking.client.first_name + ' ' + booking.client.last_name,
          }
        });
        setData(awaitingPayments);
      }).catch(() => {
        alert('We encountered some error, please try again later.');
        onHide();
      })
  }

  const confirmAll = () => {
    confirmAllPayments(trainingId)
      .then((data) => {
        const confirmedPayments = data.filter((p) => p.status).length;
        notify(() => <span>
          Congratulations!<br/>
          You have just confirmed <strong>{confirmedPayments}</strong> trainings and the money was transferred to your account.
        </span>,'success');
        onHide();
      }).catch(() => {
        alert('We encountered some error, please try again later.');
        setConfirming(false);
      })
    setConfirming(true);
  }


  return (
    <Modal
      show={true}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirm Payments for <strong>{training.name}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0, maxHeight: '60vh', overflow: 'auto' }}>
        { !data ?
          <div className="flex justify-center items-center">
            <Loader />
          </div>
          :
          data.length > 0 ?
            <div>
              <Table striped bordered responsive hover style={{ margin: 0 }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  { data.map((booking, index) => {
                      const { isPackage, package_start, package_end, date, fullname, email, fromTime, toTime } = {...booking};
                      return (
                        <tr key={index}>
                          <td>{(index + 1)}</td>
                          <td>
                            {
                              isPackage ?
                                formatDate(package_start) + ' - ' + formatDate(package_end)
                              :
                                formatDate(date) + ' ' + formatTime(fromTime) + ' - ' + formatTime(toTime)
                            }
                          </td>
                          <td>{fullname}</td>
                          <td>{email}</td>
                        </tr>
                      )
                    }
                  )}

                </tbody>
              </Table>
            </div>
          :
            <div className="flex  justify-center m-5">
              <h4>There are no payments awaiting your confirmation</h4>
            </div>
        }

      </Modal.Body>
      <Modal.Footer className="flex">
        { data?.length > 0 ?
            hasBankAccount ?
              <Button className="flex justify-center"
                type="button"
                disabled={confirming}
                onClick={confirmAll}>
                  { confirming ?
                    <ButtonLoader
                      type="ThreeDots"
                      color="white"
                      className="ml-2 self-center"
                      height={10}
                      width={25}
                    /> : "Confirm All"
                  }
              </Button>
              :
              <span>
                Before you can confirm you need to add your back account first. You can do that
                  <a href="/card" class="mx-1 font-black underline">
                    here
                  </a>
              </span>
          : ''
        }
      </Modal.Footer>
    </Modal>
  );
}

export default enhancer(ConfirmAllPaymentsModal);
