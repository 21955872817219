import React, { useState } from 'react';
import { UserActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from '../Loader';
import { notify } from '../../../utils/utilities';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const history = useHistory();
  let { loader } = props;

  const submit = async e => {
    e.preventDefault();
    props.showHUD();
    let data = await props.getResetPassword(email);
    props.hideHUD();
    if (data && data.response) {
      notify('Email has sent to reset password', 'success');
      history.push('/');
    }
  };

  return (
    <div className="font-mono bg-main-background">
      <div className="min-h-screen h-full flex justify-center">
        <div className="flex justify-center">
          <div className="w-full flex justify-center items-center min-h-full h-full">
            <div className="w-full p-5 m-4 md:w-3/4 rounded-lg bg-white shadow-md">
              <div className="px-2 my-2 text-center">
                <h3 className="pt-4 mb-2 text-xl text-primary-color font-sf-medium">
                  Forgot Your Password?
                </h3>

                <p className="mb-4 text-center text-xs text-gray-700 font-sf-regular">
                  We get it, stuff happens. Just enter your email address below and we'll send you a
                  link to reset your password!
                </p>
              </div>

              <form className="pt-6 pb-8 mb-4 bg-white rounded" onSubmit={e => submit(e)}>
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700 font-sf-regular"
                    for="email">
                    Email
                  </label>

                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                    id="email"
                    autoFocus={true}
                    required
                    type="email"
                    placeholder="Enter Email Address..."
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-6 text-center">
                  <button
                    className="w-full px-4 py-2 font-sf-medium text-white bg-primary-color rounded-md hover:bg-black focus:outline-none focus:shadow-outline"
                    type="submit">
                    Reset Password
                  </button>
                </div>

                {loader && (
                  <div className="flex justify-center items-center">
                    <Loader small />
                  </div>
                )}

                <hr className="mb-6 border-t" />

                <div className="flex-column flex align-items-center">
                  <button
                    disabled={loader}
                    type="button"
                    onClick={() => history.goBack()}
                    className="border font-sf-semibold rounded-md px-3 hover:bg-gray-800 text-black hover:text-white text-xs py-2">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhancer(ForgotPassword);
