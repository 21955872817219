import React, { useState } from 'react';
import {
  formatDate,
  formatTime,
  getDay,
  getDays,
  borderColor,
  convertDate,
} from '../../../utils/utilities';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { AvailabilitySetting } from '../../Popup';

const DashboardAvailabilityMini = props => {
  const [selected, setSelected] = useState(null);
  let availabilityPagy = props.availabilities.toJS().availabilityPagy;
  let availabilities =
    (props.availabilities.toJS().availabilities && props.availabilities.toJS().availabilities) ||
    [];

  const handlePageClick = data => {
    props.getAvailabilities(data.selected + 1);
  };

  const getSingleAvailability = id => {
    props.getAvailability(id).then(response => {
      let { data } = response;
      if (data.id) props.openUpdateDateSelection(data.id, data.attributes);
    });
  };

  return (
    <div>
      {availabilities.length > 0 ? (
        availabilities.map((availability, index) => (
          <div
            key={index}
            onClick={() => setSelected(null)}
            className={`flex w-full mt-2 shadow-lg rounded-lg border-l-4 ${borderColor(
              availability.attributes.training_type
            )}  items-center bg-white`}>
            <div className="w-full bg-white overflow-hidden p-2 flex flex-col justify-center">
              <span className="primary-text-regular text-t-gray">
                Date:
                <small className="primary-text-regular ml-2">
                  {moment(availability.attributes.repetition_from).isSame(
                    moment(availability.attributes.repetition_until)
                  )
                    ? convertDate(formatDate(availability.attributes.repetition_from))
                    : convertDate(formatDate(availability.attributes.repetition_from)) +
                      ' - ' +
                      convertDate(formatDate(availability.attributes.repetition_until))}
                </small>
              </span>

              <span className="primary-text-regular text-t-gray">
                Time:
                <small className="primary-text-regular ml-2">
                  {formatTime(availability.attributes.from_time)}
                  {' - '}
                  {formatTime(availability.attributes.to_time)}
                </small>
              </span>

              <span className="primary-text-regular text-t-gray">
                Type:
                <small className="primary-text-regular ml-2">
                  {availability.attributes.training_type}
                </small>
              </span>

              <span className="primary-text-regular text-t-gray">
                Days:
                <small className="primary-text-regular ml-2">
                  {availability.attributes.repetition_frequency === 'daily'
                    ? getDay(availability.attributes.repetition_from)
                    : getDays(availability.attributes.days).length === 7 ||
                      getDays(availability.attributes.days).length === 0
                    ? 'All Days'
                    : getDays(availability.attributes.days)}
                </small>
              </span>
            </div>

            <div className="w-2/12 bg-white">
              <AvailabilitySetting
                id={availability.id}
                setSelected={() => {
                  setSelected(availability.id);
                }}
                selected={selected}>
                <div className="content-center flex-col">
                  <button
                    onClick={e => {
                      setSelected(null);
                      e.stopPropagation();
                      getSingleAvailability(availability.id);
                    }}
                    className="flex items-center text-black hover:text-gray-500 focus:outline-none p-2">
                    Edit
                  </button>

                  <button
                    onClick={e => {
                      setSelected(availability.id);
                      e.stopPropagation();
                      props.deleteEvent(availability.id);
                    }}
                    className="flex items-center text-black hover:text-gray-500 focus:outline-none p-2">
                    Delete
                  </button>
                </div>
              </AvailabilitySetting>
            </div>
          </div>
        ))
      ) : (
        <h3 className={'font-sf-semibold text-center text-sm text-black'}>
          No availabilities ahead.
        </h3>
      )}

      {availabilityPagy && (
        <div className="mt-4 text-primary-color">
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={<span className="gap">...</span>}
            pageCount={availabilityPagy.pages}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousLinkClassName={'previous_page'}
            nextLinkClassName={'next_page'}
            disabledClassName={'disabled'}
            activeClassName={'active'}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardAvailabilityMini;
