import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import { getConsumerUrl } from '../../../utils/utilities';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
});

let enhancer = connect(connectState, connectProps);

const TabButton = ({ name, pathname, handleSelect, location }) => (
  <div className="w-full md:flex-1 pt-2 relative shrink-width expand-width">
    <button onClick={() => handleSelect(pathname)} className=" focus:outline-none font-sf-medium">
      {name}
    </button>

    {location.pathname === `/${pathname}` && (
      <div className="h-1 w-8 rounded-lg bg-white" style={{ position: 'absolute', bottom: -10 }} />
    )}
  </div>
);

function TopTabs(props) {
  const history = useHistory();
  const location = useLocation();
  const handleSelect = route => {
    props.refreshAthlete();
    history.location.pathname = `/${route}`;
    props.closeNav()
    history.push(route);
  };

  let currentUser = props.currentUser && props.currentUser.attributes;

  return (
    <div className="flex w-full flex-wrap items-start">
      <TabButton
        name="Dashboard"
        pathname="dashboard"
        handleSelect={handleSelect}
        location={location}
      />

      <TabButton
        name="Trainings"
        pathname="trainings"
        handleSelect={handleSelect}
        location={location}
      />

      <TabButton
        name="Bookings"
        pathname="bookings"
        handleSelect={handleSelect}
        location={location}
      />

      <TabButton
        name="Booking Page"
        handleSelect={() =>
          window.open(`${getConsumerUrl()}/${currentUser && currentUser.slug}`, '_blank')
        }
        location={location}
      />

      <TabButton
        name="Admin"
        pathname="admin"
        handleSelect={handleSelect}
        location={location}
      />
    </div>
  );
}

export default enhancer(TopTabs);
