import React, { useState, useEffect } from 'react';
import moment from 'moment';
import TimePicker from '../TimePicker/TimePicker';
import { UserActions, ServiceActions, CalendarActions } from '../../../store/actions';
import { connect } from 'react-redux';
import {
  formatDate,
  convertDate,
  isTimeAfter,
  isFutureTime,
  notify,
} from '../../../utils/utilities';
import { InputLabel } from '../../Shared';

let connectProps = {
  ...UserActions,
  ...ServiceActions,
  ...CalendarActions,
};

let enhancer = connect(null, connectProps);

function UpdateAvailability(props) {
  const [startTime, setStart] = useState(null);
  const [endTime, setEnd] = useState(null);

  useEffect(() => {
    if (props.editEvent) {
      setData();
    }
  }, []);

  const setData = () => {
    setStart(formatTime(props.info.attributes.from_time));
    setEnd(formatTime(props.info.attributes.to_time));
  };

  const setStartTime = date => {
    setStart(moment(date.value, 'h:mmA').format('HH:mm') + ':00');
  };

  const setEndTime = date => {
    setEnd(moment(date.value, 'h:mmA').format('HH:mm') + ':00');
  };

  const updateEntry = async () => {
    if (!isTimeAfter(startTime, endTime)) notify('End time should be after from time');
    else if (!isFutureTime(props.info.attributes.booking_date, startTime)) {
      return;
    } else {
      let payload = {
        calendar_entry: {
          from_time: startTime,
          to_time: endTime,
        },
      };
      await props.updateEntry(payload, props.info.id);
      props.passUpdatedEntries();
      props.hideSideOver();
    }
  };

  const formatTime = time => {
    let updatedTime = moment(time).utc().format('hh:mm a').toUpperCase().replace(/\s/g, '');
    return `${moment(updatedTime, 'hh:mm a').format('HH:mm')}:00`;
  };

  return (
    <div>
      <div className="flex-row flex items-center justify-center min-h-full bg-transparent">
        <div className="flex-col px-4 flex w-full">
          <div className="flex flex-col">
            <div
              style={{ height: 1, backgroundColor: '#DBDBDB' }}
              className="flex w-11/12 my-4 self-center"
            />

            <h7 className="text-black font-sf-medium text-center">Edit availability</h7>

            <div className="flex justify-between items-center w-full pt-4">
              <div className="w-1/2">
                <InputLabel label="Start Time" />
                <div>
                  <TimePicker onChange={setStartTime} name="From" value={startTime} />
                </div>
              </div>

              <small className="text-black px-2 font-sf-semibold">{' - '}</small>

              <div className="w-1/2">
                <InputLabel label="End Time" />
                <TimePicker value={endTime} onChange={setEndTime} name="To" />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full justify-center mt-10">
            <button onClick={() => updateEntry()} className="primary-dark-button w-full">
              Apply to {convertDate(formatDate(props.info.attributes.booking_date))} only
            </button>
          </div>

          <div className="w-full flex mb-3 justify-end mt-3">
            <button
              onClick={props.showPopup}
              type="submit"
              className="w-full border border-gray-600 hover:text-white hover:bg-primary-color text-gray-700 text-sm py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
              <small className="font-sf-regular opacity-75 text-sm">Delete Availability</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhancer(UpdateAvailability);
