import React, {useState} from 'react';
import SelectSearch from "react-select-search";
import Script from 'react-load-script';

function GooglePlacesInput({ setData, value, setValue, onChange }) {
  const [state, setState] = useState({
    autocompleteList: [{ value, name: value }],
    lastQuery: ''
  });
  const [currentValue, setCurrentValue] = useState(value);

  const geocodeByPlaceId = (id) => {
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;

    return new Promise((resolve, reject) => {
      geocoder.geocode({
          placeId: id,
          language: 'en'
        },
        (results, status) => {
          if (status !== OK) {
            return reject(status);
          }
          return resolve(results);
        });
    });
  }

  const onPlaceSelected = (selectedPlaceId) => {
    const entry = state.autocompleteList.find((e) => e.value === selectedPlaceId);

    geocodeByPlaceId(selectedPlaceId).then(result => {
      var address = result[0].address_components;
      var city = '',
          state = '',
          zip = '',
          street = '';

      address &&
      address.forEach(function (component) {
        var types = component.types;

        if (types.indexOf('street_number') > -1) {
          street = component.long_name;
        }

        if (types.indexOf('locality') > -1) {
          city = component.long_name;
        }

        if (types.indexOf('administrative_area_level_1') > -1) {
          state = component.short_name;
        }

        if (types.indexOf('postal_code') > -1) {
          zip = component.long_name;
        }
      });

      let data = { city, state, zip, street };

      onChange({
        asString: entry.name,
        data
      });
      setCurrentValue(selectedPlaceId);
    });


  }

  const findPlaces = (query) => {
    if (!query || query === state.lastQuery) {
      return state.autocompleteList;
    }

    const GoogleAutocomplete = new window.google.maps.places.AutocompleteService();
    return GoogleAutocomplete
        .getPlacePredictions(
            {
              input: query,
              language: 'en',
              componentRestrictions: { country: ['us', 'ca'] },
              types: [
                '(regions)',
              ],
            })
        .then(({ predictions }) => {
          const data = predictions.map((p) => ({
            name: p.description,
            value: p.place_id
          }))
          setState({
            lastQuery: query,
            autocompleteList: data
          });

          return data;
        });
  }

  return (
    <div className="small">
      <Script url={'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_MAP_KEY + '&libraries=places'}/>
      <SelectSearch
         value={currentValue}
         options={state.autocompleteList}
         onChange={onPlaceSelected}
         getOptions={findPlaces}
         debounce={500}
         search
         placeholder="Start typing to begin search"
       />
    </div>
  );
}

export default GooglePlacesInput;
