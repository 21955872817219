import React, { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import TimePicker from '../Athlete/TimePicker/TimePicker';
import { days, userChoice, trainingOption } from '../../constants';
import {
  formatDate,
  isFutureTime,
  notify,
  isDateAfter,
  isTimeAfter,
  isTimeDurationEligible,
  getTrainingColor,
  getTrainingType,
} from '../../utils/utilities';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CustomDatePicker, TrainingTags, SelectionButton, InputLabel } from '../Shared';
import {
  availabilityPopup,
  multipleTimeAvailability,
  oneTimeAvailability,
} from '../../stringConstants';

function CreateAvailability(props) {
  const {
    trainingsPagy,
    getBulkTrainings,
    onboarding,
    buttonLoading,
    changeContent,
    selectedTrainingType,
    trainings,
  } = props;
  const [fromTime, setFrom] = useState('8:00:00');
  const [toTime, setTo] = useState('9:00:00');
  const [weekDays, setWeekDays] = useState(days);
  const [daysToPass, setDaysToPass] = useState([]);
  const [step, setStep] = useState(0);
  const [choice, setChoice] = useState(userChoice.ONETIME);
  const [trainingType, setTrainingType] = useState(selectedTrainingType);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [singleSelection, setSingleSelection] = useState(new Date());
  const [excludedTrainings, setExcluded] = useState([]);
  const [allDaysSelect, setAllDaysSelect] = useState(false);

  const handleDaySelection = passed => {
    const mapped = weekDays.map(item =>
      passed.day === 'All'
        ? { ...item, checked: passed.checked ? false : true }
        : item.day === passed.day
          ? { ...item, checked: !item.checked }
          : item
    );

    setWeekDays(mapped);

    let filtered = mapped
      .filter(item => {
        return item.checked;
      })
      .map(item => item.value);

    setDaysToPass(filtered);
  };

  const renderDays = () => {
    return weekDays.map((item, index) => (
      <SelectionButton
        text={item.day}
        key={index}
        checked={item.checked}
        onClick={() => {
          handleDaySelection(item);
        }}
        value={item.day}
        days={true}
      />
    ));
  };

  useEffect(() => {
    setFrom('8:00:00');
    setTo('9:00:00');
    setWeekDays(days);
    setDaysToPass([]);
    setStep(0);
    setChoice(userChoice.ONETIME);
    props.selectedTraining && setTrainingType(props.selectedTraining.trainingType);
  }, [props.refreshModal]);

  const handleOnChange = (type, date) => {
    type === 'start' ? setStartDate(date) : setEndDate(date);
  };

  const handleSelectSingle = date => {
    setSingleSelection(date);
  };

  const applyChanges = force_overlap => {
    const repetition_frequency = choice === userChoice.ONETIME ? 'daily' : 'weekly';
    const repetition_from =
      choice === userChoice.ONETIME ? formatDate(singleSelection) : formatDate(startDate);
    const repetition_until =
      choice === userChoice.REPEAT ? formatDate(endDate) : formatDate(singleSelection);

    if (!isTimeAfter(fromTime, toTime)) notify('End time should be after from time');
    else if (choice === userChoice.REPEAT && !isDateAfter(startDate, endDate))
      notify('End date should be after start date.');
    else if (respectiveTrainings && respectiveTrainings.filter(item => item.checked).length === 0)
      notify('Please select the trainings to tag!');
    else if (props.providedServices.length > 0 && trainingType === null)
      notify('Please select the training type!');
    else if (!isTimeDurationEligible(fromTime, toTime, respectiveTrainings))
      notify('Please increase your time duration.');
    else {
      if (!isFutureTime(repetition_from, fromTime)) {
        return;
      }

      const payload = {
        availability: {
          from_time: fromTime,
          to_time: toTime,
          repetition_frequency,
          repetition_from,
          repetition_until,
          training_type: getTrainingType(trainingType),
          training_color: getTrainingColor(trainingType),
          selected_trainings:
            respectiveTrainings &&
            respectiveTrainings.filter(item => item.checked).map(item => item.id),
        },
      };

      if (choice === userChoice.REPEAT) {
        payload.availability.days = daysToPass;
      }

      if (force_overlap) {
        payload.availability.user_force_overlap = true;
      }

      props.applyDateSelection(
        payload,
        choice === userChoice.ONETIME
          ? `Events scheduled on ${formatDate(repetition_from)}`
          : `Events can be scheduled from ${formatDate(repetition_from)} to ${formatDate(endDate)}`,
        null,
        force_overlap ? true : false
      );

      if (onboarding) {
        props.changeButtonLoading(false);
        props.changeContent();
      }
    }
  };

  useMemo(() => {
    if (props.selectedTraining) {
      let excluded =
        trainingType &&
        trainings
          .filter(({ attributes }) => {
            return (
              attributes.training_type === trainingType.toLowerCase() &&
              attributes.id !== props.selectedTraining.trainingId
            );
          })
          .map(({ attributes }) => attributes.id);

      excluded && setExcluded(excluded);
    }
  }, [trainingType]);

  const respectiveTrainings = useMemo(() => {
    return (
      trainingType &&
      trainings
        .filter(({ attributes }) => {
          return (
            attributes.training_type === trainingType.toLowerCase() &&
            attributes.status === 'published' &&
            !attributes.deleted &&
            !attributes.package
          );
        })
        .map(
          ({ attributes: { name, id, training_type, privacy_setting, duration } }, index, arr) => {
            const checked = excludedTrainings.includes(id) ? false : true;

            return {
              name,
              id,
              privacy_setting,
              checked:
                excludedTrainings.length === 0 && training_type === 'group' && arr.length !== 1
                  ? false
                  : checked, //Default false for every group trainings. User can select one only
              training_type,
              duration,
            };
          }
        )
    );
  }, [excludedTrainings, trainingType]);

  const handleTagSelection = (checked, item) => {
    let update = [];

    if (checked) {
      update = [...excludedTrainings, item.id];
      setExcluded(update);
    } else {
      if (item.training_type === 'group') {
        //To enable toggle buttons behaviour for group trainings
        update = [];

        respectiveTrainings
          .map(single => {
            if (single.id === item.id) {
              update = update.filter(id => id !== single.id);
            } else {
              update = [...update, single.id];
            }
          });

        setExcluded(update);
      } else {
        update = excludedTrainings.filter(id => id !== item.id);
        setExcluded(update);
      }
    }
  };

  const checkHideForOB = trainingType => {
    let show = true;
    if (onboarding && selectedTrainingType) {
      show = trainingType === selectedTrainingType;
    }
    return show;
  };

  const trainingTypeToDisplay = optionType => {
    return props.providedServices && props.providedServices.includes(optionType.toLowerCase());
  };

  const isTrainingTypeSelected = optionType => {
    return trainingType && trainingType.toLowerCase() === optionType.toLowerCase();
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className="p-2 max-h-screen">
        <div className="flex-col bg-white px-4 flex py-4 minimum-height">
          <div className={`flex flex-col px-4 relative`}>
            {!onboarding && (
              <div className="self-center flex flex-col items-center">
                <small className="text-sm text-primary-color font-sf-medium">
                  Create Availability
                </small>

                <small className="primary-text-regular normal-case text-t-gray text-center">
                  {availabilityPopup}
                </small>
              </div>
            )}

            {!onboarding && (
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  props.closeModal();
                }}
                className="absolute top-0 right-0 rounded-lg focus:outline-none self-end">
                <small className="hover:text-blue-500 text-primary-color font-medium text-lg">
                  X
                </small>
              </button>
            )}
          </div>

          <div className="flex flex-col">
            {step === 0 && (
              <div className="my-3 focus:outline-none content-start justify-start flex-col">
                <InputLabel label="Option" />

                <div className="flex justify-between w-full mb-3">
                  <div className="pr-2 w-1/2">
                    <SelectionButton
                      text={userChoice.ONETIME}
                      checked={choice === userChoice.ONETIME}
                      onClick={setChoice}
                      value={userChoice.ONETIME}
                    />
                  </div>

                  <div className="pl-2 w-1/2">
                    <SelectionButton
                      text={userChoice.REPEAT}
                      checked={choice === userChoice.REPEAT}
                      value={userChoice.REPEAT}
                      onClick={setChoice}
                    />
                  </div>
                </div>

                <p className="text-xs font-sf-small text-center" style={{ color: '#6E7994' }}>
                  {choice === userChoice.ONETIME ? oneTimeAvailability : multipleTimeAvailability}
                </p>

                <InputLabel label="Type" />

                <div className="flex justify-between w-full">
                  {trainingTypeToDisplay(trainingOption.VIRTUAL) &&
                    checkHideForOB(trainingOption.VIRTUAL) && (
                      <SelectionButton
                        text={trainingOption.VIRTUAL}
                        checked={isTrainingTypeSelected(trainingOption.VIRTUAL)}
                        value={trainingOption.VIRTUAL}
                        onClick={value => {
                          setExcluded([]);
                          setTrainingType(value);
                        }}
                      />
                    )}

                  {trainingTypeToDisplay(trainingOption.INDIVIDUAL) &&
                    checkHideForOB(trainingOption.INDIVIDUAL) && (
                      <SelectionButton
                        text={trainingOption.INDIVIDUAL}
                        checked={isTrainingTypeSelected(trainingOption.INDIVIDUAL)}
                        value={trainingOption.INDIVIDUAL}
                        onClick={value => {
                          setExcluded([]);
                          setTrainingType(value);
                        }}
                      />
                    )}

                  {trainingTypeToDisplay(trainingOption.GROUP) &&
                    checkHideForOB(trainingOption.GROUP) && (
                      <SelectionButton
                        text={trainingOption.GROUP}
                        checked={isTrainingTypeSelected(trainingOption.GROUP)}
                        value={trainingOption.GROUP}
                        onClick={value => {
                          setExcluded([]);
                          setTrainingType(value);
                        }}
                      />
                    )}
                </div>

                {trainingType && (
                  <div className="items-start justify-start content-start flex flex-col mt-3">
                    <InputLabel label="Training Options Included" />

                    <TrainingTags
                      options={respectiveTrainings}
                      handleTagSelection={handleTagSelection}
                    />

                    {trainingsPagy.next !== null && (
                      <div
                        className="my-1 cursor-pointer"
                        onClick={() => {
                          getBulkTrainings();
                        }}>
                        <span className="text-blue-500 hover:text-black text-xs">Load more</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {choice === userChoice.ONETIME && (
              <div className="flex flex-col justify-start content-start">
                <CustomDatePicker
                  title="Select Date"
                  placeholder="Start Date"
                  handleOnChange={handleSelectSingle}
                  selected={singleSelection}
                />
              </div>
            )}

            {choice === userChoice.REPEAT && (
              <div className="flex flex-col justify-start content-start">
                <div className="flex justify-between flex-wrap">
                  <div className="w-full md:w-1/2 pr-2">
                    <CustomDatePicker
                      title="Start date"
                      placeholder="Start Date"
                      handleOnChange={date => {
                        handleOnChange('start', date);
                        handleOnChange('end', date);
                      }}
                      selected={startDate}
                    />
                  </div>

                  <div className="w-full md:w-1/2">
                    <CustomDatePicker
                      title="End date"
                      placeholder="Start Date"
                      handleOnChange={date => {
                        handleOnChange('end', date);
                      }}
                      selected={endDate}
                    />
                  </div>
                </div>
              </div>
            )}

            {choice === userChoice.REPEAT && (
              <div className="flex flex-col mt-3">
                <div className="flex justify-between">
                  <InputLabel label="Days Available" />

                  <label className="inline-flex items-center">
                    <span className="primary-text-regular normal-case">Select all</span>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setAllDaysSelect(!allDaysSelect);
                        handleDaySelection({ day: 'All', checked: allDaysSelect, value: -1 });
                      }}
                      className="form-checkbox h-3 w-3 ml-1"
                      checked={allDaysSelect}
                    />
                  </label>
                </div>

                <div className="flex no-scrollbar">
                  <div className="flex enable-wrap">{renderDays()}</div>
                </div>
              </div>
            )}

            <div className="flex justify-between w-full mt-3">
              <div className="mr-2 w-1/2 text-left">
                <InputLabel label="Availability Start Time" />

                <TimePicker
                  onChange={date => {
                    setFrom(moment(date.value, 'h:mmA').format('HH:mm') + ':00');
                    setTo(moment(date.value, 'h:mmA').format('HH:mm') + ':00');
                  }}
                  name="From"
                  value={fromTime}
                />
              </div>

              <div className="w-1/2 text-left self-end">
                <InputLabel label="Availability End Time" />

                <TimePicker
                  onChange={date => setTo(moment(date.value, 'h:mmA').format('HH:mm') + ':00')}
                  name="To"
                  value={toTime}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse items-center mt-5">
            <button onClick={() => applyChanges(false)} className="primary-dark-button w-32">
              Add Availability
              <Loader
                visible={buttonLoading}
                type="ThreeDots"
                color="white"
                className="ml-2 self-center"
                height={10}
                width={25}
              />
            </button>

            {onboarding && (
              <button
                type="button"
                onClick={changeContent}
                className="self-end px-3 underline focus:outline-none rounded py-2 text-primary-color my-3 hover:text-blue-500 text-xs inline-flex justify-center">
                Skip
              </button>
            )}
          </div>
        </div>
      </div>

      <SweetAlert
        style={{ position: 'absolute', top: 10, right: 0, left: 0 }}
        show={props.indicateOverlap !== null}
        confirmBtnText="Add Availability"
        confirmBtnBsStyle="primary"
        title={<strong className="text-red-800">Date and time overlaps</strong>}
        onCancel={() => {
          props.resetOverlapIndication();
        }}
        showCancel={true}
        onConfirm={() => {
          props.resetOverlapIndication();
          applyChanges(true);
        }}>
        <small className="text-left text-red-800">
          You have already created availabilities at this date/time
        </small>

        <table className="table-fixed border-collapse border-red-800 border w-full my-1">
          <thead>
            <th className="border">
              <small className="text-black">Date</small>
            </th>
            <th className="border">
              <small className="text-black">Time</small>
            </th>
          </thead>

          <tbody>
            <td className="border">
              <small className="text-black text-xs">
                {props.indicateOverlap !== null && props.indicateOverlap.date}
              </small>
            </td>

            <td className="border">
              <small className="text-black text-xs">
                {props.indicateOverlap !== null && props.indicateOverlap.time}
              </small>
            </td>
          </tbody>
        </table>

        <small className="text-left text-red-800">
          This is fine if you want to offer multiple training options at the same time to get more
          bookings. We just want to make sure, please confirm by clicking Add Availability or
          Cancel.
        </small>
      </SweetAlert>
    </div>
  );
}

export default CreateAvailability;
