import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import { useHistory } from 'react-router-dom';
import { Loader } from '../Loader';
import { InputLabel } from '../../Shared';
import { notify } from '../../../utils/utilities';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

function NewPassword(props) {
  const history = useHistory();
  const [reset_password_token, setResetPasswordToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirm] = useState('');

  useEffect(() => {
    let url = window.location.href;
    setResetPasswordToken(url.split('=')[1])
  }, []);

  const submit = async e => {
    e.preventDefault();

    if (password !== confirmPassword) {
      notify("Password doesn't match!");
      return;
    }

    let payload = {
      athlete: {
        reset_password_token,
        password,
        password_confirmation: confirmPassword,
      },
    };

    props.showHUD();
    let data = await props.updateWithToken(payload)
    props.hideHUD();
    if (data && data.data.id) {
      notify('Password updated successfully', 'success');
      history.push('/');
    }
  };

  let { loader } = props;

  return (
    <div className="min-h-screen h-full flex justify-center bg-main-background">
      <div className="flex justify-center w-full">
        <div className="w-full flex justify-center items-center min-h-full h-full">
          <div className="w-full p-5 rounded-lg bg-white md:w-1/3 shadow-md">
            <div className="px-2 my-2 text-center">
              <h3 className="medium-black">New password</h3>

              <small className="mb-4 primary-text-regular normal-case">
                Enter your new password for your account.
              </small>
            </div>

            <form className="pt-6 pb-8 mb-4 rounded" onSubmit={e => submit(e)}>
              <div className="mb-4">
                <InputLabel label="Password" />

                <input
                  className="text-input"
                  id="pass"
                  type="password"
                  autoFocus={true}
                  required
                  minLength={7}
                  placeholder="New password"
                  onChange={e => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <InputLabel label="Confirm Password" />

                <input
                  className="text-input"
                  id="confirmPass"
                  type="password"
                  minLength={7}
                  placeholder="Confirm new password"
                  required
                  onChange={e => setConfirm(e.target.value)}
                />
              </div>

              <div className="mb-6 text-center">
                <button className="primary-dark-button w-full" type="submit">
                  Reset Password
                </button>
              </div>

              {loader && (
                <div className="flex justify-center items-center">
                  <Loader small />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhancer(NewPassword);
