import React, {useState} from 'react';
import Switch from 'react-input-switch';

function TrayvoToggle({ onChange, value, disabled }) {
  const onToggleChanged = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <Switch
      disabled={ disabled }
      styles={{
        track: {
          backgroundColor: 'gray',
          width: '40px',
          height: '23px',
          borderRadius: 20,
        },
        trackChecked: {
          backgroundColor: '#042E60',
        },
        button: {
          backgroundColor: 'white',
          height: '20px',
          width: '20px',
          borderRadius: '10px',
        },
        buttonChecked: {
          backgroundColor: 'white',
          marginLeft: '8px'
        },
      }}
      value={value}
      on={true}
      off={false}
      onChange={onToggleChanged}
    />
  );
}

export default TrayvoToggle;
