import React, { Component } from 'react';
import {
  formatDate,
  formatTime,
  getBookingStatus,
  getPaymentStatus, notify,
} from '../../../utils/utilities';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';
import {paymentStatus, statusColor} from '../../../constants';
import BookingsCallToActionButton from './BookingsCallToActionButton';

const verticalBorder = { borderLeftWidth: 1, borderColor: '#CCCCCC' };

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  bookings: state.Bookings.booking.get('bookings'),
  bookingsPagy: state.Bookings.booking.get('bookingsPagy'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

const renderHeadings = () => {
  const heads = [
    'Training Name',
    'Consumer Name',
    'Date',
    'Time',
    'Location',
    'Status',
    'Payment',
    'Action',
  ];
  return heads.map((item, index) => {
    return (
      <th
        key={index}
        className={`font-sf-medium bg-primary-color text-white font-thin sticky-header text-center text-xs px-4 py-3 ${
          (item === 'Location' && 'mini-hide') ||
          (item === 'Details' && 'mini-hide') ||
          (item === 'Date' && 'mini-hide') ||
          (item === 'Time' && 'mini-hide')
        }`}>
        {item}
      </th>
    );
  });
};

class Bookings extends Component {
  state = {
    action: null,
    selectedId: null,
  }

  componentDidMount() {
    let { getBookings, location, showpagy } = this.props;
    if (showpagy) getBookings();
  }

  moveToDetail(id) {
    let { history } = this.props;

    history.push({
      pathname: `/bookings/${id}`,
    });
  }

  changeBookingStatus = async (id, status) => {
    this.setState({
      selectedId: id,
      action: status,
    });
    // status can have three possible values
    // null => no change;
    // false => allow client to cancel booking;
    // true => reject booking
    await this.props.updateStatus(id, status);
    this.props.getRejectedBookings();
    this.setState({
      selectedId: null,
      action: null,
    });
  };

  handlePageClick = data => {
    let { getBookings, history, fromDashboard } = this.props;
    !fromDashboard && history.push(`/bookings?page=${data.selected + 1}`);
    getBookings(data.selected + 1);
  };

  getClientName = booking => {
    let {first_name, middle_name, last_name} = booking.attributes.client.data.attributes;
    return `${first_name ?? ''} ${middle_name ?? ''} ${last_name ?? ''}`;
  }

  getStatusColor = booking => {
    let status = getBookingStatus(
      booking.attributes.status,
      booking.attributes.payment.data.attributes.status
    );
    if (status.toLowerCase() === 'upcoming') return statusColor.UPCOMING;
    else if (status.toLowerCase() === 'completed') return statusColor.COMPLETED;
    else if (status.toLowerCase() === 'confirm') return statusColor.CONFIRM;
    else return statusColor.CANCELED;
  };

  render() {
    let { bookings, bookingsPagy, loader, showpagy, searchText } = this.props;
    let filteredBookings = bookings;

    return (
      <div className="bg-main-background" fluid>
        <div>
          <div className="mt-3">
            <div className="flex flex-col">
              {!showpagy && (
                <div className="bg-white flex justify-between p-2 rounded-md">
                  <small className="text-primary-color text-sm font-sf-semibold">Bookings</small>

                  <button
                    onClick={() => this.props.history.push('bookings')}
                    className="font-sf-semibold hover:text-blue-500 text-primary-color text-xs py-2 px-1 self-end mx-2">
                    {'View All >'}
                  </button>
                </div>
              )}

              <table
                className="w-full bg-white gfg overflow-hidden tableizer-firstrow"
                style={{ borderRadius: 20 }}>
                <thead>
                  <tr className="bg-primary-color rounded-md h-8">{renderHeadings()}</tr>
                </thead>

                <tbody>
                  {filteredBookings.length > 0 ? (
                    filteredBookings.map((booking, index) => (
                      <tr
                        key={index}
                        className="cursor-pointer border-b border-main-background rt-tr-group"
                        onClick={() => this.moveToDetail(booking.id)}>
                        <td className="font-sf-regular text-center pl-2 text-gray-700 text-xs border-training-individual rounded-md border-l-2 capitalize">
                          { booking.attributes.provided_service.data.attributes.name }
                          { booking.attributes.package &&
                              <span className='ml-2 border px-2 py-1 bg-primary-color rounded-lg text-white text-xs'>
                                package
                              </span>
                            }
                        </td>

                        <td
                          className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 capitalize"
                          style={verticalBorder}>
                          {booking.attributes.client.data.attributes.first_name}{' '}
                          {booking.attributes.client.data.attributes.last_name}
                        </td>

                        <td
                          className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide"
                          style={verticalBorder}>

                          { booking.attributes.provided_service.data.attributes.package ?
                            formatDate(booking.attributes.provided_service.data.attributes.package_end)
                            :
                            formatDate(booking.attributes.booking_date)
                          }
                        </td>

                        <td
                          className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide"
                          style={verticalBorder}>
                          { booking.attributes.package ? '-'
                            :
                            formatTime(booking.attributes.from_time) + ' - ' + formatTime(booking.attributes.to_time)
                          }
                        </td>

                        <td
                          className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide"
                          style={verticalBorder}>
                          {booking.attributes.provided_service.data.attributes.training_type ===
                          'virtual'
                            ? 'Virtual training'
                            : booking.attributes.provided_service.data.attributes
                                .provided_service_locations.data[0].attributes.location &&
                              booking.attributes.provided_service.data.attributes
                                .provided_service_locations.data[0].attributes.location}
                        </td>

                        <td
                          className="font-sf-regular text-center pl-2 py-1 mini-hide"
                          style={verticalBorder}>
                          <div
                            className={`w-16 mx-auto font-sf-regular text-center text-white capitalize h-6 rounded-lg`}
                            style={{ backgroundColor: this.getStatusColor(booking) }}>
                            <small style={{ fontSize: 10 }}>
                              {getBookingStatus(
                                booking.attributes.status,
                                booking.attributes.payment.data.attributes.status
                              )}
                            </small>
                          </div>
                        </td>

                        <td
                          className="font-sf-regular text-center pl-2 py-1 mini-hide"
                          style={verticalBorder}>
                          <div className="font-sf-semibold text-center pl-2 text-primary-color text-xs py-1 mini-hide">
                            <small style={{ fontSize: 10 }}>
                              {getPaymentStatus(
                                booking.attributes.status,
                                booking.attributes.payment.data.attributes.status
                              )}
                            </small>
                          </div>
                        </td>

                        <td
                          className="font-sf-regular flex text-center pl-2 text-primary-color text-xs py-1 mini-hide justify-center items-center"
                          style={verticalBorder}>

                          {/*{ booking.attributes.status + ':' + booking.attributes.payment?.data?.attributes.status }*/}

                          <br/>
                          <br/>

                          <div className='flex flex-col justify-center'>

                            { ![paymentStatus.ACCEPTED, paymentStatus.REFUNDED].includes(booking.attributes.payment?.data?.attributes.status)
                              &&
                              <BookingsCallToActionButton
                                loader={loader}
                                loaderVisibility={this.state.selectedId === booking.id && this.state.action}
                                onClick={e => {
                                  e.stopPropagation();
                                  this.changeBookingStatus(booking.id, true);
                                }}
                                className="align-self-center"
                                buttonText={"Reject"}/>
                            }

                            { booking.attributes.status === 'incomplete' ?
                              <>
                                {!booking.attributes.allowed_cancel ? (
                                  <BookingsCallToActionButton
                                    loader={loader}
                                    loaderVisibility={this.state.selectedId === booking.id && !this.state.action}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.changeBookingStatus( booking.id, false );
                                      notify(
                                        `Cancellation request approved and ${this.getClientName(booking)} has been notified to complete process and get refunded`,
                                        'success'
                                      );
                                    }}
                                    buttonText="Allow to Cancel/Get Refund"/>
                                ) : (
                                  <BookingsCallToActionButton
                                  loader={loader}
                                  loaderVisibility={this.state.selectedId === booking.id && !this.state.action}
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.changeBookingStatus( booking.id, false );
                                  }}
                                  buttonText={"Disallow to Cancel"}/>
                                )}
                            </>
                            :
                              <small className="text-xs flex font-sf-medium items-center p-1 px-2 text-blue-600 hover:bg-blue-600 hover:text-white rounded-md focus:outline-none">
                                View
                              </small>
                            }
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div fluid className="flex items-center justify-center p-4">
                          {loader ? (
                            <Loader />
                          ) : (
                            <h3 className={'font-sf-semibold text-center text-sm text-black'}>
                              No Bookings Available.
                            </h3>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {bookingsPagy && showpagy && (
              <div className="mt-4 text-primary-color">
                <ReactPaginate
                  previousLabel={'«'}
                  nextLabel={'»'}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={bookingsPagy.pages}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={'pagination'}
                  previousLinkClassName={'previous_page'}
                  nextLinkClassName={'next_page'}
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(Bookings);
