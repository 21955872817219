import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const SelectionButton = ({ item, checked, onClick, text }) => {
  return (
    <div className="w-full pr-1 pt-1">
      <button
        onClick={() => onClick(checked, item)}
        className={`uppercase py-2 rounded text-xs text-gray-600 border-2 w-full px-2 flex items-center shadow-sm hover:shadow-md outline-none focus:outline-none ${
          checked ? ' border-blue-800' : 'opacity-50'
        }`}>
        {item.privacy_setting === 'private' && (
          <FontAwesomeIcon icon={faLock} size="xs" color="gray" />
        )}
        <small className="text-xs font-sf-regular pl-1 capitalize text-primary-color">{text}</small>
      </button>
    </div>
  );
};

function TrainingTags(props) {
  if (props.options.length > 0) {
    return (
      <div className="flex flex-wrap">
        {props.options.map((item, index) => {
          return (
            <div className={`flex items-center`}  key={index}>
              <SelectionButton
                text={item.name}
                checked={item.checked}
                item={item}
                onClick={props.handleTagSelection}
              />
            </div>
          );
        })}
      </div>
    );
  } else
    return (
      <span className="text-gray-700 font-sf-regular">
        Make sure to turn on the trainings under this training type!
      </span>
    );
}

export default TrainingTags;
