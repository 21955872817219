import React from 'react';

function InputLabel({ label, color, bold }) {
  const fontWeight = bold ? 'medium-black' : 'font-sf-regular';
  return (
    <small
      className={fontWeight}
      style={{ color: color || '#6E7994', fontSize: '12.75px' }}>
      {label}
    </small>
  );
}
export default InputLabel;
