import React from 'react';

function ModalBackground({ tailwindOpacityClass }) {
  const opacity = tailwindOpacityClass || 'opacity-75'

  return (
    <div className="fixed inset-0 transition-opacity pin">
      <div className={`absolute inset-0 bg-gray-900 ${opacity}`} />
    </div>
  );
}

export default ModalBackground;
