import React from 'react';
import { CreateAvailability, ModalBackground } from '../Shared';

function DateSelection({
  closeModal,
  providedServices,
  getBulkTrainings,
  trainingsPagy,
  trainings,
  selectedTrainingType,
  ...props
}) {
  return (
    <div
      className="fixed pin pb-4 inset-0 inset-x-0 flex items-center justify-center z-10"
      onClick={e => e.stopPropagation()}>
      <ModalBackground tailwindOpacityClass='opacity-25' />

      <div
        className="bg-white pb-20 shadow-xl transform transition-all w-full border-gray-700 border-2 overflow-scroll"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style={{ width: '500px', maxHeight: window.innerHeight / 1.3, borderRadius: '10px' }}>
        <CreateAvailability
          {...props}
          closeModal={closeModal}
          providedServices={providedServices}
          trainingsPagy={trainingsPagy}
          trainings={trainings}
          getBulkTrainings={getBulkTrainings}
          selectedTrainingType={selectedTrainingType}
        />
      </div>
    </div>
  );
}

export default DateSelection;
