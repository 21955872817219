import React, { Component } from 'react';
import { formatDate, formatTime } from '../../../utils/utilities';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  bookings: state.Bookings.booking.get('bookings'),
  bookingsPagy: state.Bookings.booking.get('bookingsPagy'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

class BookingsMini extends Component {
  componentDidMount() {
    let { getBookings, showpagy } = this.props;
    if (showpagy) getBookings();
  }

  moveToDetail(id) {
    let { history } = this.props;
    history.push({
      pathname: `/bookings/${id}`,
    });
  }

  rejectBooking = async id => {
    await this.props.updateStatus(id, 'rejected');
    this.props.getRejectedBookings();
  };

  handlePageClick = data => {
    let { getBookings } = this.props;
    getBookings(data.selected + 1);
  };

  render() {
    let { bookings, bookingsPagy, loader, showpagy, searchText } = this.props;
    let filteredBookings =
      searchText !== ''
        ? bookings.filter(booking =>
            booking.attributes.client.data.attributes.contact_number
              .toLowerCase()
              .includes(searchText && searchText.toLowerCase())
          )
        : bookings;

    return (
      <div className="bg-main-background">
        <div className={`mt-2 ${showpagy && 'border border-cus-gray'}`}>
          <div className="flex flex-col">
            {filteredBookings.length > 0 ? (
              filteredBookings.map(
                ({
                  attributes: {
                    id,
                    booking_date,
                    from_time,
                    to_time,
                    provided_service,
                    client,
                    status,
                  },
                }) => (
                  <div key={id}
                    className="w-full bg-white mt-2 shadow-lg border-l-4 border-training-individual cursor-pointer rounded-lg"
                    onClick={() => this.moveToDetail(id)}>
                    <div className="flex">
                      <div className="w-1/3 flex flex-col items-center justify-center content-center border-black b-r-1 my-3">
                        <small className="font-sf-regular text-black text-center text-xxs">
                          {formatDate(booking_date)}
                        </small>

                        <small className="font-sf-regular text-black text-center text-xxs">
                          {formatTime(from_time)}-{formatTime(to_time)}
                        </small>
                      </div>

                      <div className="w-full mx-2 relative flex flex-col justify-center py-3 capitalize">
                        <small className="medium-black font-bold text-left text-xxs">
                          {provided_service.data.attributes.name}
                        </small>

                        <small className="font-sf-regular text-black text-left text-xxs">
                          {client.data.attributes.first_name} {client.data.attributes.last_name}
                        </small>

                        <small className="primary-text-regular text-xxs">
                          {provided_service.data.attributes.training_type === 'virtual'
                            ? 'Virtual training'
                            : provided_service.data.attributes.provided_service_locations.data[0]
                                .attributes.location &&
                              provided_service.data.attributes.provided_service_locations.data[0]
                                .attributes.location}
                        </small>

                        <div className="flex font-sf-semibold text-center justify-center items-center text-sm py-3 absolute right-0 self-center">
                          <small
                            className={`w-20 text-xxs text-center text-black capitalize font-sf-regular py-1 rounded-lg ${
                              status === 'completed'
                                ? 'bg-green-600 text-white'
                                : status === 'rejected'
                                ? 'bg-red-600 text-white '
                                : 'text-black'
                            }`}>
                            {status}
                          </small>

                          {status === 'incomplete' && (
                            <button
                              disabled={loader}
                              onClick={e => {
                                e.stopPropagation();
                                this.rejectBooking(id);
                              }}
                              className="flex font-sf-semibold text-xxs items-center ml-3 p-1 text-red-700 hover:bg-red-600 hover:text-white rounded-md focus:outline-none">
                              Reject
                              {loader && (
                                <div className="ml-2">
                                  <Loader small />
                                </div>
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <h3 className={'font-sf-semibold text-center text-sm text-black'}>
                No Bookings Available
              </h3>
            )}
          </div>

          {bookingsPagy && (
            <div className="mt-4 text-primary-color">
              <ReactPaginate
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={<span className="gap">...</span>}
                pageCount={bookingsPagy.pages}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={'pagination'}
                previousLinkClassName={'previous_page'}
                nextLinkClassName={'next_page'}
                disabledClassName={'disabled'}
                activeClassName={'active'}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default enhancer(BookingsMini);
