import React, { Component } from 'react';
import Loader from '../../Shared/Loader';

class BookingsCallToActionButton extends Component {
  getCssClasses = () => {
    return `flex font-sf-medium items-center p-1 text-red-700 hover:bg-red-600 hover:text-white rounded-md focus:outline-none ${this.props.className}`;
  };

  render = () => {
    return (
      <button className={this.getCssClasses()} onClick={this.props.onClick} disabled={this.props.loader}>
        <small className='text-xs'>{this.props.buttonText}</small>
        {(this.props.loader && this.props.loaderVisibility) && (
          <div className='ml-2'>
            <Loader small />
          </div>
        )}
      </button>
    );
  }
}

export default BookingsCallToActionButton;
