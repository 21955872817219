import React, { Component } from 'react';
import { UserActions, ServiceActions, CalendarActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { CFormGroup } from '@coreui/react';
import ButtonLoader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { DurationPicker } from '../DurationPicker';
import Select from 'react-select';
import { Loader } from '../Loader';
import {
  accentColor,
  availableSlots,
  privacySettings,
  trainingOption,
  GENDER_OPTIONS,
  GradeOptions,
  GradesValues,
  availableSessionsCountOptions
} from '../../../constants';
import {
  SelectionButton,
  ToggleButton,
  ModalBackground,
  InputLabel,
  SlugChangeInfoModal,
  CustomDatePicker
} from '../../Shared';
import ServiceLocation from './ServiceLocation';
import {
  notify,
  priceFormatter,
  removeSpecialCharacters,
  getConsumerUrl,
  isTrainingLocationPresent, slashedDate,
} from '../../../utils/utilities';
import { addConnectionDetailsGuide, addLocationGuide } from '../../../stringConstants';
import Tour from 'reactour';
import SelectSearch from "react-select-search";
import TrayvoToggle from "../../Shared/TrayvoToggle";
import momentTimezone from "moment-timezone";

let connectProps = {
  ...UserActions,
  ...ServiceActions,
  ...CalendarActions,
};

let connectState = state => ({
  providedServices: state.Service.service.get('providedServices'),
  singleService: state.Service.service.get('singleService'),
  services: state.Service.service.get('services'),
  loader: state.User.meta.get('showHUD'),
  currentUser: state.User.current.get('currentUser'),
  buttonLoading: state.User.meta.get('buttonLoading'),
});

let enhancer = connect(connectState, connectProps);

const initialStates = {
  editVisible: false,
  serviceObj: null,
  duration: 'Min',
  minDuration: null,
  cusDuration: null,
  link: '',
  oldTrainingSlug: '',
  price: '',
  desc: '',
  package: false,
  package_start: null,
  package_end: null,
  sessions_count: null,
  trainingImage: '',
  connectionDetails: '',
  selectedTraining: null,
  trainingImageFile: null,
  service: '',
  privacySetting: null,
  gender: 'unisex',
  minGrade: null,
  maxGrade: null,

  status: 'published',
  locations: [],
  locationsNew: [],
  eventName: '',
  training_type: null,
  maxSeats: null,
};

const WARNING_TEXT_TEMPLATE = `You have updated the name of this training to <strong>{newName}</strong>. This name change can also change your link you share for this training to get booked. <br/>
<br/>
If you accept this change, then any existing links you have shared will not work anymore. See below and choose your best option:<br/>
<br/>
Current link - <strong>{oldLink}</strong><br/>
New link - <strong>{newLink}</strong>`;
let WARNING_TEXT = '';
class EditTraining extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...initialStates,
      showGuide: false,
      showModal: false
    };
  }

  async componentDidMount() {
    let { showHUD, clearSingleService, getSingleService, serviceId } = this.props;

    showHUD();
    clearSingleService();
    let data = await getSingleService(serviceId);

    if (data?.data?.attributes) {
      let dur = parseInt(data.data.attributes.duration).toString();

      if (!(dur === '30' || dur === '45' || dur === '60')) {
        this.setState({ cusDuration: dur });
      }

      const packageStart = data.data.attributes.package_start;
      const packageEnd = data.data.attributes.package_end;

      this.setState({
        minDuration: dur,
        package: data.data.attributes.package,
        sessions_count: { label: data.data.attributes.sessions_count, value: data.data.attributes.sessions_count },
        package_start: packageStart ? slashedDate(packageStart) : null,
        package_end: packageEnd ? slashedDate(packageEnd) : null,
        has_bookings: data.data.attributes.has_bookings,
        price: priceFormatter(data.data.attributes.price),
        desc: data.data.attributes.discription,
        trainingImage: data.data.attributes.image_url,
        link: data.data.attributes.slug.split('-')[1],
        oldTrainingSlug: data.data.attributes.slug.split('-')[1],
        locations: data.data.attributes.provided_service_locations.data,
        connectionDetails: data.data.attributes.connection_detail,
        eventName: data.data.attributes.name,
        privacySetting:
          data.data.attributes.privacy_setting === 'public'
            ? privacySettings[0]
            : privacySettings[1],
        training_type:
          data.data.attributes.training_type === 'virtual'
            ? trainingOption.VIRTUAL
            : data.data.attributes.training_type === 'individual'
              ? trainingOption.INDIVIDUAL
              : trainingOption.GROUP,

        maxSeats: { label: data.data.attributes.max_seats, value: data.data.attributes.max_seats },
        gender: data.data.attributes.gender,
        minGrade: data.data.attributes.min_grade,
        maxGrade: data.data.attributes.max_grade,
        showGuide: isTrainingLocationPresent(data.data.attributes),
      });
    }
  }

  async handleAccept() {
    const { link } = this.state;
    this.setState({ showModal: false });
    await this.handleEditTrainingOptions(link);
  };

  async handleReject() {
    const { oldTrainingSlug } = this.state;
    this.setState({ showModal: false });
    await this.handleEditTrainingOptions(oldTrainingSlug);
  };

  handleDissmis() {
    this.setState({ showModal: false });
  }

  slugChangedConfirm() {
    this.setState({ showModal: true });
  };

  onMaxGradeChanged = (option) => {
    const { minGrade } = this.state;

    this.setState({
      maxGrade: option,
      invalidGrades: this.gradesInvalid(minGrade, option)
    });
  }

  onMinGradeChanged = (option) => {
    const { maxGrade } = this.state;

    this.setState({
      minGrade: option,
      invalidGrades: this.gradesInvalid(option, maxGrade)
    });
  }

  gradesInvalid = (min, max) => {
    if (min && max) {
      const minVal = GradesValues[min];
      const maxVal = GradesValues[max];

      return maxVal < minVal;
    }

    return false;
  }

  allGradesToggle = (allowAll) => {
    if (allowAll) {
      this.setState({
        minGrade: null,
        maxGrade: null,
        invalidGrades: false
      });
    }
  }

  async submit(e) {
    e.preventDefault();

    let {
      eventName,
      link,
      oldTrainingSlug,
      cusDuration,
      minDuration,
      minGrade,
      maxGrade,
      package_start,
      package_end,
      sessions_count
    } = this.state;

    const { currentUser } = this.props;

    if (this.state.package) {
      if (!package_start || !package_end) {
        notify('Please select when the package starts and when it ends');
        return;
      }
      if (package_start.getTime() > package_end.getTime()) {
        notify('Package start and end dates are incorrect');
        return;
      }
      if (!sessions_count) {
        notify('Please select sessions count in the package');
        return;
      }
    }

    if (!cusDuration && !minDuration) {
      notify('Select a duration');
      return;
    }

    const notFullRangeDefined = (!minGrade && maxGrade) || (minGrade && !maxGrade);
    if (notFullRangeDefined) {
      notify('Please select grade range');
      return;
    }

    this.props.changeButtonLoading(true);

    if (link && link !== oldTrainingSlug) {
      const linkBase = `trayvo.com/${currentUser?.attributes?.slug}/`
      WARNING_TEXT = WARNING_TEXT_TEMPLATE
        .replace('{newName}', eventName)
        .replace('{oldLink}', linkBase + oldTrainingSlug)
        .replace('{newLink}', linkBase + link)
      this.slugChangedConfirm();

    } else {
      await this.handleEditTrainingOptions(link);
    }

  }

  handleEditTrainingOptions = async (trainingSlug) => {
    let {
      trainingImage,
      trainingImageFile,
      status,
      cusDuration,
      minDuration,
      locationsNew,
      locations,
      eventName,
      connectionDetails,
      privacySetting,
      training_type,
      maxSeats,
      gender,
      minGrade,
      maxGrade,
      price,
      desc,
    } = this.state;

    let { currentUser, singleService } = this.props;

    let payload = {
      provided_service: {
        discription: desc,
        package: this.state.package,
        package_start: null,
        package_end: null,
        slug_title: `${currentUser.attributes.slug}/${removeSpecialCharacters(trainingSlug)}`,
        price: price,
        status,
        name: eventName,
        duration: parseInt(cusDuration ? cusDuration : minDuration),
        gender,
        min_grade: minGrade,
        max_grade: maxGrade,
        privacy_setting: privacySetting
          ? privacySetting.value
          : singleService.attributes.privacy_setting === 'public'
            ? 1
            : 2,
        training_type:
          training_type === trainingOption.VIRTUAL
            ? 1
            : training_type === trainingOption.INDIVIDUAL
              ? 2
              : 3,
        provided_service_locations_attributes: locations
          .map(item => ({
            id: item.attributes.id,
            location: item.attributes.location,
            address: item.attributes.address,
            _destroy: item.attributes._destroy ? true : false,
          }))
          .concat(
            locationsNew.map(item => ({
              location: item.location,
              address: item.address,
            }))
          ),
      },
    };

    if (this.state.package) {
      payload.provided_service.package_start = moment(this.state.package_start).format('YYYY-MM-DD');
      payload.provided_service.package_end = moment(this.state.package_end).format('YYYY-MM-DD');
      payload.provided_service.sessions_count = this.state.sessions_count.value;
    }

    if (training_type === trainingOption.VIRTUAL) {
      payload.provided_service.connection_detail = connectionDetails;
    }

    if (training_type === trainingOption.GROUP || training_type === trainingOption.VIRTUAL) {
      payload.provided_service.max_seats = maxSeats
        ? maxSeats.value
        : singleService.attributes.max_seats;
    }

    if (trainingImage && trainingImageFile) {
      let data = await this.props.getTrainingSignedUrl();
      await this.props.uploadImage(trainingImageFile, data.signed_url);
      payload.provided_service.image_url = data.url;
    }

    await this.props.updateProvidedService(singleService.attributes.id, payload);
    await this.props.getProvidedServices();
    const { data: providedService } = await this.props.getSingleService(
      singleService.attributes.id
    );

    this.props.setSelectedTraining({
      trainingType: providedService.attributes.training_type,
      trainingId: providedService.attributes.id,
    });

    this.props.changeButtonLoading(false);

    this.setState({ ...initialStates }, () => {
      notify('Updated successfully', 'success');

      const openAvailabilityModal = !providedService?.attributes?.availability_set && !providedService?.attributes.package;

      this.props.closePopup({
        showAvailabilityPopup: openAvailabilityModal,
      });
    });
  }

  onChange = (tag, value) => {
    this.setState({ [tag]: value });
  };

  updatePublishedStatus = async (id, status) => {
    await this.props.updateProvidedService(id, {
      provided_service: {
        status: status,
      },
    });
    await this.props.getSingleService(id);
  };

  addLocation = () => {
    let { locationsNew } = this.state;
    this.setState({
      showGuide: false,
      locationsNew: [...locationsNew, { id: locationsNew.length + 1, location: '', address: '' }],
    });
  };

  removeOldLocation = id => {
    let { locations } = this.state;
    let removeLocations = locations.map(item => {
      if (item.attributes.id === id) item.attributes['_destroy'] = true;
      return item;
    });

    this.setState({
      locations: removeLocations,
    });
  };

  removeNewLocation = id => {
    let { locationsNew } = this.state;
    let removeLocations = locationsNew.filter(item => item.id !== id);
    this.setState({
      locationsNew: removeLocations,
    });
  };

  onUpdateOldLocations = (id, tag, value) => {
    let { locations } = this.state;
    let updatedLocations = locations.map(item => {
      if (item.attributes.id === id) item.attributes[tag] = value;
      return item;
    });

    this.setState({ locations: updatedLocations });
  };

  onUpdateNewLocations = (id, tag, value) => {
    let { locationsNew } = this.state;
    let updatedLocations = locationsNew.map(item => {
      if (item.id === id) item[tag] = value;
      return item;
    });
    this.setState({ locationsNew: updatedLocations });
  };

  render() {
    let { buttonLoading, currentUser, singleService, loader } = this.props;

    let {
      minDuration,
      cusDuration,
      price,
      link,
      training_type,
      desc,
      locations,
      locationsNew,
      locationAddress,
      eventName,
      privacySetting,
      connectionDetails,
      maxSeats,
      gender,
      minGrade,
      maxGrade,
      showModal,
      package_start,
      package_end,
      sessions_count,
      has_bookings
    } = this.state;

    const isPackage = this.state.package;

    const allGrades = minGrade === null && maxGrade === null;
    const multipleLocations = locations.length + locationsNew.length > 1;

    return (
      <div
        className={
          this.props.isVisible
            ? `fixed pin pb-4 inset-0 inset-x-0 inset-y-0 flex items-center justify-center z-10`
            : 'hidden'
        }>
        <ModalBackground />

        {showModal ?
          <SlugChangeInfoModal
            onDissmis={() => this.handleDissmis()}
            onAccept={() => this.handleAccept()}
            onReject={() => this.handleReject()}
            acceptText={'Change to New Link'}
            rejectText={'Keep Current Link'}
            text={WARNING_TEXT} />
          : ''
        }

        <div
          className="bg-white overflow-scroll max-h-screen shadow-xl transform transition-all w-full border-gray-700 border"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ width: '500px', height: window.innerHeight / 1.1, borderRadius: '10px' }}>
          <div className="p-2">
            <div className="flex justify-between items-center pl-4 pb-3">
              <div>
                <ToggleButton
                  status={singleService?.attributes?.status}
                  training={singleService?.attributes}
                  id={0}
                  handleOnToggle={() => {
                    this.updatePublishedStatus(
                      singleService?.attributes?.id,
                      singleService?.attributes?.status === 'published'
                        ? 'unpublished'
                        : 'published'
                    );
                  }}
                />
              </div>

              <small className="text-sm text-black">Update Training Option</small>

              <button
                type="button"
                onClick={() => this.props.closePopup()}
                className="p-4 rounded-lg focus:outline-none">
                <small className="hover:text-blue-500 text-primary-color font-medium">X</small>
              </button>
            </div>

            {!loader && singleService?.attributes ? (
              <div className="flex py-1 flex-col bg-extra-gray rounded-md items-center">
                <small className="text-black font-sf-regular text-xs">
                  Last edited{' '}
                  {moment(singleService?.attributes?.updated_at).format('LL, h:mm:ss a')}
                </small>

                {singleService?.attributes?.status === 'published' && (
                  <a
                    className="font-sf-regular underline text-blue-400 "
                    href={`${getConsumerUrl()}/${singleService.attributes.slug.replace(/-/g, '/')}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      style={{ color: '#67B4EB', marginRight: 10 }}
                    />
                    view live page
                  </a>
                )}

                <form
                  className="w-full"
                  onSubmit={e => {
                    this.submit(e);
                  }}>
                  <div className="px-10">
                    <InputLabel label="Type" />

                    {training_type && (
                      <div className="flex justify-between w-full">
                        <SelectionButton
                          text={trainingOption.VIRTUAL}
                          checked={
                            training_type.toUpperCase() === trainingOption.VIRTUAL.toUpperCase()
                          }
                          value={trainingOption.VIRTUAL}
                          onClick={() => {
                            this.setState({ training_type: trainingOption.VIRTUAL });
                          }}
                        />

                        <SelectionButton
                          text={trainingOption.INDIVIDUAL}
                          checked={
                            training_type.toUpperCase() === trainingOption.INDIVIDUAL.toUpperCase()
                          }
                          value={trainingOption.INDIVIDUAL}
                          onClick={() => {
                            this.setState({
                              training_type: trainingOption.INDIVIDUAL,
                            });
                          }}
                        />

                        <SelectionButton
                          text={trainingOption.GROUP}
                          checked={
                            training_type.toUpperCase() === trainingOption.GROUP.toUpperCase()
                          }
                          value={trainingOption.GROUP}
                          onClick={() => {
                            this.setState({ training_type: trainingOption.GROUP });
                          }}
                        />
                      </div>
                    )}

                    { /**
                      PACKAGE
                    */ }

                    <div className="my-3">

                      <div className="flex justify-between">
                        <InputLabel className="md:w-1/2"
                                    color={ isPackage ? '#000' : null }
                                    label="Package" />

                        <div className="inline-flex md:w-auto pr-3">
                          <TrayvoToggle
                            value={isPackage}
                            disabled={has_bookings}
                            id={0}
                            onChange={(newValue) => {
                              this.setState({
                                package: newValue
                              });
                            }}
                          />
                        </div>
                      </div>

                      { has_bookings &&
                        <div className="flex items-center">
                            <span className='md:w-auto text-xs' style={{ color: 'red' }}>
                              Training already has a booking
                            </span>
                        </div>
                      }

                      <div className='my-2 text-justify text-xs' style={{ color: isPackage ? '#000' : '#bbb' }}>
                        Package options are used when offering your customer multiple training sessions for a set price, i.e. 5 training sessions for $200
                      </div>

                      {
                        isPackage &&
                        <div className="flex flex-col justify-start content-start">
                          <div className="flex justify-between flex-wrap">
                            <div className="w-full md:w-1/2 pr-2" style={{pointerEvents: has_bookings ? 'none': 'all'}}>
                              <CustomDatePicker
                                title="Package starts"
                                placeholder="First training date"
                                disabled={has_bookings}
                                minDate={package_start}
                                handleOnChange={(date) => this.setState({ package_start: date })}
                                selected={package_start}
                              />
                            </div>

                            <div className="w-full md:w-1/2 pr-2" style={{pointerEvents: has_bookings ? 'none': 'all'}}>
                              <CustomDatePicker
                                title="Package ends"
                                placeholder="Last training date"
                                disabled={has_bookings}
                                minDate={package_end}
                                handleOnChange={(date) => this.setState({ package_end: date })}
                                selected={package_end}
                              />
                            </div>
                          </div>
                          <div className='my-2 text-justify text-xs' style={{ color: !has_bookings ? '#000' : '#bbb' }}>
                            Enter date range (first training date-last training date) of your package for customers to see. You can display specific dates/times in the description below.
                          </div>

                          <div className="flex flex-col">
                            <InputLabel label="Sessions" />
                            <div className="text-center w-full">
                              <Select
                                className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black "
                                placeholder="Sessions count"
                                isSearchable={false}
                                isDisabled={has_bookings}
                                required
                                value={sessions_count}
                                onChange={option => this.onChange('sessions_count', option)}
                                options={availableSessionsCountOptions}
                              />
                            </div>
                          </div>
                          <div className='my-2 text-justify text-xs' style={{ color: !has_bookings ? '#000' : '#bbb' }}>
                            Enter the # of sessions included in this package for customers to see
                          </div>

                        </div>
                      }

                    </div>
                    <DurationPicker
                      minDuration={minDuration}
                      cusDuration={cusDuration}
                      setCusDuration={cusDuration => this.setState({ cusDuration })}
                      setMinDuration={minDuration => this.setState({ minDuration })}
                    />

                    <div className="flex justify-between mt-2">
                      <div className="w-1/2 pr-1">
                        <InputLabel
                          label={`Training Name (${eventName?.length || 0}/35 characters)`}
                          color={eventName?.length === 35 && 'red'}
                        />

                        <Form.Control
                          type="text"
                          id="custom-group"
                          maxLength={35}
                          placeholder="Offensive Training"
                          className="font-sf-regular bg-transparent text-black text-xs mr-2"
                          onChange={e => {
                            this.onChange('eventName', e.target.value);
                            this.onChange(
                              'link',
                              e.target.value.toLowerCase().replace(/\s/g, '').replace(/-/g, '')
                            );
                          }}
                          value={eventName}
                          required
                        />
                      </div>

                      <div className="w-1/2 pr-1">
                        <InputLabel label="Cost" />
                        <CFormGroup id="custom-group">
                          <div className="text-left">
                            <NumberFormat
                              value={price}
                              thousandSeparator={true}
                              placeholder="$ 0.00"
                              allowNegative={false}
                              className="font-sf-regular bg-transparent text-black text-xs rounded form-control"
                              prefix={'$ '}
                              onValueChange={({ formattedValue, value }) => {
                                this.onChange('price', value);
                              }}
                            />
                          </div>
                        </CFormGroup>
                      </div>
                    </div>

                    <Form.Group>
                      {training_type !== trainingOption.VIRTUAL && (
                        <div
                          className="flex flex-col"
                          key={locations?.length + locationsNew?.length}>
                          {locations
                            .filter(item => !item.attributes._destroy)
                            .map((item, index) => {
                              return (
                                <ServiceLocation
                                  hideRemove={index === 0 && true}
                                  key={index}
                                  withBorder={multipleLocations}
                                  allLocations={locations.filter(loc => !loc.attributes._destroy)}
                                  id={item.attributes.id}
                                  locationValue={item.attributes.location}
                                  totalCharacters={item.attributes.location?.length || 0}
                                  addressValue={item.attributes.address}
                                  onUpdateLocations={(id, tag, value) =>
                                    this.onUpdateOldLocations(id, tag, value)
                                  }
                                  removeLocation={id => this.removeOldLocation(id)}
                                />
                              );
                            })}

                          {locationsNew.map((item, index) => (
                            <ServiceLocation
                              id={item.id}
                              withBorder={multipleLocations}
                              locationValue={item.location}
                              addressValue={item.address}
                              key={index}
                              totalCharacters={item.location?.length || 0}
                              onUpdateLocations={(id, tag, value) =>
                                this.onUpdateNewLocations(id, tag, value)
                              }
                              removeLocation={id => this.removeNewLocation(id)}
                            />
                          ))}
                          <div
                            className="my-1 cursor-pointer add-location-btn"
                            onClick={() => this.addLocation()}>
                            <span className="text-blue-500">
                              {[...locations, ...locationsNew].length > 0
                                ? '+ Add Another Location'
                                : '+ Add Location'}
                            </span>

                            <Tour
                              steps={addLocationGuide}
                              isOpen={this.state.showGuide}
                              accentColor={accentColor}
                              onRequestClose={() => this.setState({ showGuide: false })}
                              showNavigation={false}
                              showButtons={false}
                            />
                          </div>
                        </div>
                      )}

                      {training_type === trainingOption.VIRTUAL && (
                        <>
                          <div>
                            <InputLabel label="Connection details" />
                            <Form.Control
                              type="text"
                              placeholder="Enter Zoom, Google Meet, or other link"
                              className="font-sf-regular text-black text-xs connection-details-input"
                              onClick={() => this.setState({ showGuide: false })}
                              onChange={e => {
                                this.setState({ showGuide: false });
                                this.onChange('connectionDetails', e.target.value);
                              }}
                              value={connectionDetails}
                              required
                            />
                          </div>

                          <Tour
                            steps={addConnectionDetailsGuide}
                            isOpen={this.state.showGuide}
                            accentColor={accentColor}
                            onRequestClose={() => this.setState({ showGuide: false })}
                            showNavigation={false}
                            showButtons={false}
                          />
                        </>
                      )}

                      {(training_type === trainingOption.VIRTUAL || training_type === trainingOption.GROUP) && (
                        <div className="flex flex-col">
                          <InputLabel label="Available Spots" />
                          <div className="text-center w-full">
                            <Select
                              className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black "
                              placeholder="Available spots"
                              isSearchable={false}
                              required
                              value={maxSeats}
                              onChange={option => this.onChange('maxSeats', option)}
                              options={availableSlots}
                            />
                          </div>
                        </div>
                      )}

                    </Form.Group>

                    <Form.Group>
                      <div className="flex flex-col">
                        <InputLabel label="Gender" />
                        <div className="text-center w-full">
                          <SelectSearch
                            options={GENDER_OPTIONS}
                            onChange={option => this.onChange('gender', option)}
                            value={gender}
                            placeholder="Select gender"
                          />
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <div className="flex justify-between">
                        <InputLabel label="Ages (i.e. Grades 9-12)" />

                        <label className="inline-flex items-center">
                          <span className="primary-text-regular normal-case">All ages</span>
                          <input
                            type="checkbox"
                            onChange={(event) => this.allGradesToggle(event.target.checked)}
                            className="form-checkbox h-3 w-3 ml-1"
                            checked={allGrades}
                          />
                        </label>
                      </div>

                      <div className="flex justify-between">
                        <div className="w-1/2 pr-1">
                          <InputLabel label="Minimum Grade" />
                          <div className="text-center w-full max-results-height-250">
                            <SelectSearch
                              options={GradeOptions}
                              onChange={option => this.onMinGradeChanged(option)}
                              value={minGrade}
                              placeholder="Select Min Grade"
                            />
                          </div>
                        </div>
                        <div className="w-1/2 pr-1">
                          <InputLabel label="Maximum Grade" />
                          <div className="text-center w-full max-results-height-250">
                            <SelectSearch
                              options={GradeOptions}
                              onChange={option => this.onMaxGradeChanged(option)}
                              value={maxGrade}
                              placeholder="Select Max Grade"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={'text-center'}>
                        <span className={`ml-2 text-xs text-red-800 transition-opacity duration-300 ${this.state.invalidGrades ? 'opacity-100' : 'opacity-0'}`}>
                          Min Grade has to be equal to or less than Max Grade
                        </span>
                      </div>
                    </Form.Group>

                    <CFormGroup>
                      <InputLabel
                        label={`Description (${desc?.length || 0}/200 characters)`}
                        color={desc?.length === 160 && 'red'}
                      />
                      <textarea
                        className="font-sf-regular text-input"
                        value={desc}
                        maxLength={200}
                        aria-multiline={true}
                        placeholder={ isPackage ? 'Add package details here, including specific dates/times for each session' : 'Description'}
                        rows={3}
                        id="ccnumber"
                        required
                        onChange={e => {
                          this.onChange('desc', e.target.value);
                        }}
                      />
                    </CFormGroup>

                    <CFormGroup>
                      <InputLabel label="Event link" />

                      <div className="flex md:items-center flex-col md:flex-row">
                        <span className="font-sf-regular text-sm mr-2 text-black">{`trayvo.com/${currentUser?.attributes?.slug}/`}</span>

                        <Form.Control
                          type="text"
                          className=" font-sf-regular bg-transparent text-black text-xs"
                          onChange={e => {
                            this.setState({ link: e.target.value.toLowerCase() });
                          }}
                          value={removeSpecialCharacters(link)}
                          required
                        />
                      </div>
                    </CFormGroup>

                    <div className="w-full">
                      <Select
                        className="primary-text-regular normal-case"
                        placeholder="Privacy"
                        isSearchable={false}
                        value={privacySetting}
                        onChange={option => this.setState({ privacySetting: option })}
                        options={privacySettings}
                      />
                    </div>

                    <div className="flex flex-row-reverse mt-3">
                      <button
                        disabled={this.state.invalidGrades}
                        type="submit"
                        className="ml-2 px-3 py-2 bg-primary-color text-white rounded p-1 hover:bg-blue-700 text-xs inline-flex justify-center">
                        Update Training Option
                        <ButtonLoader
                          visible={buttonLoading}
                          type="ThreeDots"
                          color="white"
                          className="ml-2 self-center"
                          height={10}
                          width={25}
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(EditTraining);
