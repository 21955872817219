import React from 'react';
import {
  reviewStepBottom,
  reviewStepTop,
  bookingPageLink,
  inviteCodeText,
} from '../../../stringConstants';
import { AthleteInfo } from '../Dashboard';
import { TrainingBadge, ClipboardCopyButton } from '../../Shared';
import { Placeholder } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  formatDate,
  formatTime,
  convertDate,
  borderColor,
  priceFormatter,
  getDay,
  getDays,
} from '../../../utils/utilities';

const TrainingCard = ({
  attributes: {
    name,
    privacy_setting,
    image_url,
    training_type,
    duration,
    provided_service_locations,
    price,
    status,
    id,
    slug,
  },
}) => (
  <div
    className={`hover:bg-gray-400 relative trainings-margin shadow-md p-2 bg-white mx-1 rounded-lg cursor-pointer border-t-4 ${borderColor(
      training_type
    )}`}
    style={{ height: '275px', minWidth: '220px' }}>
    <div className="flex flex-col">
      <div className="self-center rounded-full items-center">
        <img
          src={image_url ? image_url : Placeholder}
          alt=""
          className="object-cover h-16 w-16 rounded-full overflow-hidden"
        />
      </div>

      <div className="flex flex-col mx-1 items-center pt-3">
        <div className="flex">
          {privacy_setting === 'private' && (
            <FontAwesomeIcon icon={faLock} size="lg" color="gray" className="mr-1 p-1" />
          )}

          <small
            style={{
              fontSize: name && name.length > 30 ? 10 : 13,
            }}
            className="font-sf-medium text-black capitalize overflow-hidden text-xs">
            {name.length > 30 ? `${name.substring(0, 30)}...` : name}
          </small>
        </div>

        <div className="text-center max-location-height overflow-hidden pt-1">
          <small className="font-sf-regular text-gray-900 font-normal text-xs">
            {parseInt(duration)} mins
          </small>

          {training_type !== 'virtual' &&
            provided_service_locations.data &&
            provided_service_locations.data.length > 0 && (
              <small className="font-sf-regular text-gray-900 font-normal text-xs text-center capitalize">
                <small className="text-black px-1">@</small>
                {provided_service_locations.data.slice(0, 2).map(({ attributes }, index, arr) => {
                  return attributes.location + `${index < arr.length - 1 ? ' • ' : ''}`;
                })}
                {provided_service_locations.data.length > 2 && '• •'}
              </small>
            )}
        </div>

        <small className="font-sf-medium text-gray-900 capitalize text-sm font-normal self-center py-2">
          {priceFormatter(price)}
        </small>

        <TrainingBadge trainingType={training_type} />

        <div className="absolute bottom-0 left-0 p-2 w-full">
          <ClipboardCopyButton
            status={status}
            slug={slug}
            id={id}
            visible={false}
            handleOnToggle={() => {}}
            training={null}
          />
        </div>
      </div>
    </div>
  </div>
);

const AvailabilityCard = ({
  attributes: {
    repetition_from,
    repetition_until,
    from_time,
    to_time,
    repetition_frequency,
    days,
    training_type,
  },
}) => (
  <div
    className={`hover:bg-gray-400 overflow-visible relative trainings-margin shadow-md p-2 bg-white mx-1 rounded-lg cursor-pointer border-t-4 ${borderColor(
      training_type
    )}`}
    style={{ height: '275px' }}>
    <div className="flex flex-col text-black items-center">
      <small className="text-black font-sf-regular text-xl" style={{ fontSize: 32 }}>
        {convertDate(formatDate(repetition_from))}
      </small>

      {!moment(repetition_from).isSame(moment(repetition_until)) && (
        <div className="flex flex-col items-center">
          <div style={{ height: 30, width: 1, backgroundColor: '#CCCC' }} />

          <small className="text-black font-sf-regular text-xl" style={{ fontSize: 32 }}>
            {convertDate(formatDate(repetition_until))}
          </small>
        </div>
      )}

      <small className="text-black font-sf-regular text-xs py-2">
        {formatTime(from_time)} -{formatTime(to_time)}
      </small>

      <small className="text-primary-color font-sf-regular text-xs py-2">
        {repetition_frequency === 'daily'
          ? getDay(repetition_from)
          : getDays(days).length === 7 || getDays(days).length === 0
          ? 'All Days'
          : getDays(days)}
      </small>

      <div className="absolute bottom-0 flex justify-center p-2 w-3/4 my-2 self-center border-t border-gray-500 mx-3">
        <TrainingBadge trainingType={training_type} />
      </div>
    </div>
  </div>
);

function ReviewStep({
  trainings,
  firstAvailability,
  history,
  currentUser,
  changeContent,
  selectedTrainingType,
}) {
  let filteredTrainings = trainings.filter(({ attributes }) => {
    return selectedTrainingType
      ? attributes.training_type === selectedTrainingType.toLowerCase()
      : attributes;
  });

  return (
    <div className="w-full px-4 flex flex-col content-center">
      <div className="text-center mt-4">
        <small className="font-sf-regular text-gray-600 text-sm">{reviewStepTop}</small>
      </div>

      <div style={{ height: 1, width: '100%', backgroundColor: '#CCCC' }} className="my-4" />

      <h4 className="text-primary-color font-sf-regular">Training Info </h4>

      <small className="primary-text-regular normal-case text-sm">
        Here are the full details of your first training option and availability
      </small>

      <div className="flex w-full content-center items-center flex-wrap mt-2 py-2">
        <div
          className={`flex  ${
            filteredTrainings.length > 1 ? 'w-full overflow-scroll no-scrollbar' : 'w-full md:w-1/2'
          }`}>
          {filteredTrainings.map(({ attributes }, index) => (
            <div className="w-full" key={index}>
              <TrainingCard attributes={attributes} />
            </div>
          ))}
        </div>

        <div className={`w-full md:w-1/2 ${filteredTrainings.length > 1 && 'pt-2'}`}>
          {firstAvailability.length > 0 ? (
            <AvailabilityCard attributes={firstAvailability[0].attributes} />
          ) : (
            <div>
              <small className="primary-text-regular pl-2 py-1">No availabilities set.</small>
            </div>
          )}
        </div>
      </div>

      <div style={{ height: 1, width: '100%', backgroundColor: '#CCCC' }} className="my-8" />

      <h4 className="text-primary-color font-sf-regular">Booking Page </h4>

      <small className="font-sf-regular text-center text-gray-600 text-sm py-1">
        {bookingPageLink}
      </small>

      <AthleteInfo currentUser={currentUser} history={history} onboarding bookingLinkOnly />

      <div style={{ height: 1, width: '100%', backgroundColor: '#CCCC' }} className="my-8" />

      <h4 className="text-primary-color font-sf-regular">Invite Code </h4>

      <small className="font-sf-regular text-center text-gray-600 text-sm py-1">
        {inviteCodeText}
      </small>

      <AthleteInfo currentUser={currentUser} history={history} onboarding inviteOnly />

      <div style={{ height: 1, width: '100%', backgroundColor: '#CCCC' }} className="my-8" />

      <small className="primary-text-regular text-center pl-2 py-1 normal-case text-sm">
        {reviewStepBottom}
      </small>

      <button
        type="button"
        onClick={changeContent}
        className="self-end primary-dark-button w-24 my-4">
        Next
      </button>
    </div>
  );
}

export default ReviewStep;
