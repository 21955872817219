import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

function AvailabilitySetting(props) {
  const [active, setActive] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={e => {
            e.stopPropagation();
            setActive(!active);
            props.setSelected();
          }}
          className="flex items-center focus:outline-none hover:bg-primary-color p-1">
          <FontAwesomeIcon icon={faEllipsisV} size="1x" color="#737373" />
        </button>
      </div>

      {active && props.id === props.selected && (
        <div className="origin-top-right absolute right-0 rounded-md shadow-lg z-10">
          <div className="rounded-md bg-white shadow-xs px-3">
            <div className="py-1">{props.children}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AvailabilitySetting;
