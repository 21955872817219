import React, { useState, useEffect } from 'react';
import { Tick, Cross } from '../../assets/img';
import { DateSelection, AssociatedAvailabilities } from '../Popup';
import { UserActions, ServiceActions, CalendarActions } from '../../store/actions';
import { connect } from 'react-redux';
import { getTrainingTypes, notify } from '../../utils/utilities';

let connectProps = {
  ...UserActions,
  ...ServiceActions,
  ...CalendarActions,
};

let connectState = state => ({
  providedServices: state.Service.service.get('providedServices'),
  providedServicesPagy: state.Service.service.get('providedServicesPagy'),
  services: state.Service.service.get('services'),
  loader: state.User.meta.get('showHUD'),
  buttonLoading: state.User.meta.get('buttonLoading'),
  currentUser: state.User.current.get('currentUser'),
  selectedTrainingType: state.User.meta.get('selectedTrainingType'),
});

let enhancer = connect(connectState, connectProps);

function AvailabilitySet(props) {
  let { providedServicesPagy, training, providedServices, status } = props;
  const [showAvailabilities, setShowAvailabilities] = useState(false);

  const isPackage = training?.package;

  useEffect(() => {
    props.setSelectedTraining(null);
  }, []);

  const availabilityCTA = (e) => {
    if (isPackage) {
      return;
    }
    e.stopPropagation();
    status
      ? props.availabilitySet
        ? setShowAvailabilities(true)
        : createAvailability()
      : notify('Please enable this training option first');
  };

  const createAvailability = () => {
    props
      .setSelectedTraining({ trainingType: training.training_type, trainingId: training.id })
      .then(() => {
        props.openDateSelection();
      });
  };

  return (
    <div
      className="flex items-center overflow-hidden"
      onClick={availabilityCTA}>

      { isPackage ?
        <p className="primary-text-regular px-1 text-gray-600 mb-0">Package</p>
        :
        <>
          <img src={props.availabilitySet ? Tick : Cross} className="h-3 w-4 object-contain" />

          <button
            className="focus:outline-none hover:bg-blue-100 rounded-md"
            alt="">
            <small className="primary-text-regular px-1 text-gray-600">
              {props.availabilitySet ? 'Availability set' : 'Need Availability'}
            </small>
          </button>
        </>
      }


      {props.dateSelectionModal && (
        <DateSelection
          {...props}
          closeModal={() => props.closeDateSelection()}
          providedServices={getTrainingTypes(providedServices)}
          trainings={providedServices}
          trainingsPagy={providedServicesPagy}
          getBulkTrainings={() => {}}
          selectedTrainingType={null}
        />
      )}

      {showAvailabilities && (
        <AssociatedAvailabilities
          {...props}
          close={() => setShowAvailabilities(false)}
          training={training}
        />
      )}
    </div>
  );
}
export default enhancer(AvailabilitySet);
