import React, { useReducer } from 'react';
import { Form, Card, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import { Loader, PrivacyText } from '../../Shared';

let connectProps = {
  ...UserActions,
};

let enhancer = connect(null, connectProps);

const OrLine = () => (
  <div className="flex w-full justify-between my-4 items-center">
    <div style={{ height: 1, backgroundColor: '#6D799A' }} className="flex w-full mr-1" />
    <span className="text-sm font-sf-regular text-black">Or</span>
    <div style={{ height: 1, backgroundColor: '#6D799A' }} className="flex w-full ml-1" />
  </div>
);

function LoginForm(props) {
  let loader = props.loader;

  const initialState = {
    email: '',
    password: '',
  };

  function reducer(state, action) {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const submitSignin = async e => {
    e.preventDefault();
    props.loginParent({ email: provider.email, password: provider.password });
  };

  return (
    <div className="bg-white rounded-lg flex-row d-flex justify-center">
      <div className="w-full rounded-right">
        <div className="container pt-3">
          <div className="flex flex-col w-full text-center">
            <small style={{ fontSize: '22px' }} className="primary-text-regular normal-case">
              Scheduling to make your training better
            </small>
          </div>

          <Card.Body className="">
            <Form onSubmit={submitSignin}>
              <input
                className="text-xs my-2 font-sf-regular rounded-md focus:appearance-none border border-gray-600 w-full py-3 px-8 text-gray-700 focus:outline-none"
                id="email"
                name="email"
                type="email"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                placeholder="Email"
                required
              />

              <input
                className="text-xs my-2 font-sf-regular rounded-md focus:appearance-none border border-gray-600 w-full py-3 px-8 text-gray-700 mb-1 focus:outline-none"
                name="password"
                id="password"
                type="password"
                placeholder="Password"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />

              <div className="flex items-center justify-between">
                <button
                  disabled={loader}
                  className="font-sf-regular w-full bg-primary-color hover:text-primary-color hover:bg-black text-white text-sm py-2 mt-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="submit">
                  Sign In
                </button>
              </div>

              <div className="flex justify-end">
                <button
                  disabled={loader}
                  type="button"
                  onClick={() => props.resetPassword()}
                  className="font-sf-regular text-xs hover:underline text-gray-700">
                  Forgot password?
                </button>
              </div>

              <OrLine />

              {loader && (
                <div className="flex justify-center items-center">
                  <Loader color={'#000'} small />
                </div>
              )}

              <div className="flex flex-col items-center justify-between mt-2">
                <button
                  onClick={() => props.signupParent()}
                  disabled={loader}
                  className="font-sf-regular w-full border border-gray-600 hover:text-white hover:bg-primary-color text-gray-700 text-sm py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
                  type="button">
                  Trainer Sign up
                </button>

                <PrivacyText className="text-center" />
              </div>
            </Form>
          </Card.Body>
        </div>
      </div>
    </div>
  );
}

export default enhancer(LoginForm);
