import React, { Component } from 'react';
import { Tiktok, TrayvoBlue } from '../../../assets/img';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationArrow, faPhone } from '@fortawesome/free-solid-svg-icons';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import momentTimezone from 'moment-timezone';
import { TrainingBadge } from '../../Shared';
import {
  borderColor,
  findSportName,
  formatDate,
  formatTime,
  getBookingStatus,
  getLatLng,
  getPaymentStatus,
  getSocialUrl,
  isFreeTraining,
  priceFormatter,
} from '../../../utils/utilities';
import { releasePayment } from '../../../stringConstants';
import { bookingStatus, paymentStatus } from '../../../constants';
import { Link } from 'react-router-dom';
import ContactPlayer from "../../Shared/ContactPlayer";

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  booking: state.Bookings.booking.get('booking'),
  loader: state.User.meta.get('showHUD'),
  sports: state.User.current.get('sports'),
  currentUser: state.User.current.get('currentUser'),
});

const TextContainer = ({ title, value, uppercase }) => (
  <div
    className="flex justify-between my-1 p-3 rounded-md w-full"
    style={{ backgroundColor: '#F5F6FA' }}>
    <span className="primary-text-regular">{title}</span>

    <span className={`primary-text-regular text-right ${uppercase ? 'capitalize' : 'normal-case'}`}>
      {value}
    </span>
  </div>
);

const SmallText = ({ text, black, icon, id, small }) => (
  <div>
    {text &&
      <div>
        <FontAwesomeIcon icon={icon} size="sm" color="gray" className="mr-2" />

        {id === 'email' ? (
          <button
            className="primary-text-regualar no-underline hover:no-underline normal-case text-blue-700"
            onClick={() => {
              window.open(`mailto:${text}`);
            }}
            title={text}>
            {text}
          </button>
        ) : (
          <span
            className={`font-sf-regular text-xs lowercase mt-2 pr-1 ${black ? 'text-black' : 'text-gray-700'
              } ${small ? 'lowercase' : 'capitalize'}`}
            style={{ fontSize: 11 }}>
            {text}
          </span>
        )}
      </div>
    }

  </div>
);

const ClientDetail = ({ label, value }) => (
  <div className="flex flex-col border-b mt-3 border-gray-400 w-full items-start justify-end content-end">
    <small className={`font-sf-semibold text-xs capitalize pr-1 text-gray-600`}>{label}</small>

    <small className="font-sf-regular text-xs capitalize pr-1 text-primary-color">
      {value ? value : '-'}
    </small>
  </div>
);

const RoundImage = ({ source, trainingType, blueBorder }) => {
  return (
    <div
      className={`rounded-full border-2  ${blueBorder ? 'border-blue-700' : borderColor(trainingType)
        }`}>
      <img
        src={source !== null ? source : TrayvoBlue}
        className="h-16 w-16 border rounded-full object-cover"
      />
    </div>
  );
};

const Caption = ({ caption }) => (
  <small className="font-sf-regular text-black text-left text-sm capitalize">{caption}</small>
);

const ReleasePayment = ({ status, paymentStatus, transferCharge, payment, currentUser }) => (
  <div
    className={`border-2 p-2 rounded-lg bg-white mt-2 border-red-600 flex items-center shadow-md animate-pulse ${!status || !paymentStatus ? 'opacity-50 cursor-not-allowed' : 'pulse'
      }`}>
    <small className="primary-text-regular normal-case">{releasePayment}</small>

    <button
      disabled={!status || !paymentStatus}
      onClick={() => transferCharge(payment.id, currentUser.attributes.account_id)}
      className="primary-dark-button ml-2 bg-red-600">
      Confirm
    </button>
  </div>
);

const PaymentConfirmedBanner = () => (
  <div className="border-2 p-2 rounded-lg bg-white mt-2 border-green-600 flex items-center shadow-md">
    <small className="primary-text-regular normal-case">
      Thank you for confirming your training was completed, your payment has been sent to your bank
      account and should arrive within 2 business days
    </small>
  </div>
);

let enhancer = connect(connectState, connectProps);

class BookingShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingGpsCoords: null
    };
  }

  componentDidMount() {
    this.getBooking();
  }

  async getBooking() {
    let {
      getSingleBooking,
      match: { params },
    } = this.props;

    await getSingleBooking(params.id);
    const { booking, currentUser } = this.props;

    this.extractGpsCoords(booking.attributes, currentUser.attributes);
  }

  extractGpsCoords(booking, currentUser) {
    if (booking.provided_service.training_type === 'virtual') {
      this.setState({
        bookingGpsCoords: {
          lat: currentUser.latitude,
          lng: currentUser.longitude
        }
      });
    } else {
      getLatLng(booking.address)
        .then((bookingGpsCoords) => {
          this.setState({
            bookingGpsCoords
          });
        });
    }
  }

  transferCharge = async (paymendId, accountId) => {
    await this.props.transferCharge(paymendId, accountId);
    this.getBooking();
  };

  getFullName(first_name, last_name) {
    const temp = [];

    if (first_name) {
      temp.push(first_name)
    }

    if (last_name) {
      temp.push(last_name)
    }

    return temp.join(' ');
  };

  getParentFullAddres(client) {
    const {
      parent_city,
      parent_street,
      parent_postal_code,
      parent_state
    } = client;

    const parentPostalCodeAndCity = (parent_postal_code && parent_city)
      ? parent_postal_code + ' ' + parent_city
      : parent_postal_code || parent_city || '';

    const temp = [];

    if (parent_street) {
      temp.push(parent_street)
    }

    if (parentPostalCodeAndCity) {
      temp.push(parentPostalCodeAndCity)
    }

    if (parent_state) {
      temp.push(parent_state)
    }

    return temp.join(', ');
  }

  render() {
    let { booking, updateStatus, loader, sports, currentUser } = this.props;
    let service = booking && booking.attributes.provided_service.data;
    let payment = booking && booking.attributes.payment.data;
    let client = booking && booking.attributes.client.data.attributes;
    let statusBooking = booking && booking.attributes.status;
    let statusPayment = payment && payment.attributes.status;
    const isFree = isFreeTraining(service?.attributes);

    let showPaymentConfirmedNotification = false;
    let showConfirmNotification = false;
    if (isFree === false &&
      statusBooking !== bookingStatus.CANCELED &&
      statusBooking !== bookingStatus.REJECTED &&
      statusPayment !== paymentStatus.ACCEPTED &&
      currentUser.attributes &&
      currentUser.attributes.account_id) {
      showConfirmNotification = true;
    }

    if (showConfirmNotification === false &&
      isFree === false &&
      statusBooking === bookingStatus.COMPLETED &&
      statusPayment === paymentStatus.ACCEPTED) {
      showPaymentConfirmedNotification = true;
    }

    const isPackage = booking?.attributes?.package;

    return (
      <div className="bg-main-background">
        {!loader ? (
          <div className="w-full self-center flex flex-col items-center justify-center">
            {showConfirmNotification ?
              <ReleasePayment
                status={statusBooking === bookingStatus.COMPLETED}
                paymentStatus={statusPayment === paymentStatus.PAID}
                payment={payment}
                currentUser={currentUser}
                transferCharge={(paymentId, accountId) => {
                  this.transferCharge(paymentId, accountId);
                }}
              /> : ''
            }

            {showPaymentConfirmedNotification ? <PaymentConfirmedBanner /> : ''}

            <div
              className="flex flex-col w-full md:w-3/5 md:flex-row p-4 md:py-4 justify-between bg-white shadow-md mt-5"
              style={{ borderRadius: 10 }}>
              <>
                <span
                  style={{ fontSize: 15 }}
                  className="font-sf-regular break-words text-t-gray text-xs mt-2 text-center">
                  Reach out to your trainee to discuss details of session.
                </span>

                <ContactPlayer />
              </>
            </div>

            {booking && service && client && (
              <div
                className="flex flex-col w-full md:w-3/5 md:flex-row p-4 md:p-0 justify-between bg-white shadow-md mt-3 relative"
                style={{ borderRadius: 20 }}>
                {
                  isPackage &&
                  <div className="absolute left-0 top-0 right-auto overflow-hidden" style={{ width: 160, height: 160 }}>
                    <div className="capitalize font-sf-regular font-normal md:text-sm p-1 text-center border-2 border-solid bg-primary-color text-white z-50"
                      style={{
                        borderColor: '#eee',
                        transform: 'translateY(75%) translateX(-25%) rotate(-45deg)'
                      }}>package
                    </div>
                  </div>
                }

                <div className="mb-5 px-0 md:px-5 w-full md:w-1/2">
                  <div className="flex flex-row">
                    {booking && (
                      <div className="px-2 flex flex-col w-full">
                        <div className="flex flex-col items-center">
                          <h4 className="font-sf-medium text-primary-color">Training Info</h4>

                          <RoundImage
                            source={service.attributes.image_url}
                            trainingType={service.attributes.training_type}
                          />

                          <div className="flex flex-col w-full items-center mt-3">
                            <Caption caption={service.attributes.name} />

                            <div
                              className="text-center max-location-height overflow-hidden">
                              <small className="primary-text-regular uppercase">
                                {parseInt(service.attributes && service.attributes.duration)} mins
                              </small>

                              {service.attributes.training_type !== 'virtual' &&
                                service.attributes.provided_service_locations.data &&
                                service.attributes.provided_service_locations.data.length > 0 && (
                                  <small
                                    className="primary-text-regular text-center uppercase">
                                    <small className="text-primary-color px-1">@</small>
                                    {booking.attributes.location}
                                    <br />
                                    {booking.attributes.address}
                                  </small>
                                )}
                            </div>

                            <small className="medium-black" style={{ fontSize: 16 }}>
                              {isFree ? 'Free' : priceFormatter(service.attributes.price)} {service.attributes.package ? ' for ' + service.attributes.sessions_count + ' sessions' : ''}
                            </small>

                            <TrainingBadge trainingType={service.attributes.training_type} />
                          </div>
                        </div>

                        {service.attributes.training_type === 'virtual' && (
                          <span
                            className="text-primary-color font-sf-regular text-center tex-xs">
                            Connection details: {service.attributes.connection_detail}
                          </span>
                        )}

                        <span
                          style={{ fontSize: 12 }}
                          className="font-sf-regular text-gray-600 break-words text-xs mt-2 text-center">
                          {service.attributes.discription}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="py-3">
                    <div className="flex flex-col w-full my-2 p-2">

                      {
                        service.attributes.package ?
                          <TextContainer
                            title="Package ends"
                            value={formatDate(service.attributes.package_end, 'MM-DD-YYYY')}
                          />
                          :
                          <TextContainer
                            title={formatDate(booking.attributes.booking_date)}
                            value={`${formatTime(booking.attributes.from_time)} - ${formatTime(
                              booking.attributes.to_time
                            )} (${momentTimezone.tz(currentUser.attributes.time_zone).format('z')})`}
                          />
                      }




                      <TextContainer
                        title="Price"
                        value={isFree ? 'Free' : priceFormatter(service.attributes.price)}
                      />

                      <TextContainer title="Order" value={booking && booking.id} />

                      <TextContainer
                        title="Booking Status"
                        uppercase
                        value={getBookingStatus(statusBooking, statusPayment)}
                      />

                      <TextContainer
                        title="Payment Status"
                        uppercase
                        value={getPaymentStatus(statusBooking, statusPayment)}
                      />

                      <TextContainer title="Note" value={booking && booking.attributes.notes} />

                      {statusBooking === bookingStatus.INCOMPLETE && (
                        <div className="flex flex-row items-end justify-end mt-3">
                          <button
                            onClick={() => updateStatus(booking.id, bookingStatus.REJECTED)}
                            className="primary-dark-button bg-red-700">
                            Reject Booking
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: 1, backgroundColor: '#D7DAE2' }}
                  className="my-10 hidden md:flex h-auto"
                />

                <div className="mb-5 px-2 h-auto w-full md:w-1/2">
                  {client && (
                    <div className="w-full flex items-center flex-col">
                      <h4 className="font-sf-medium text-primary-color">Player Info</h4>

                      <RoundImage source={client.profile_image} blueBorder />

                      <div className="flex flex-col w-full items-center mt-3">
                        <Caption caption={this.getFullName(client.first_name, client.last_name)} />
                        <SmallText text={client.player_email} icon={faEnvelope} id="email" small />
                        <SmallText text={client.contact_number} icon={faPhone} />

                        <SmallText
                          text={client.city + ', ' + client.state}
                          icon={faLocationArrow}
                        />

                        <div className="flex items-center content-center pt-2">
                          <Link to={`${getSocialUrl('facebook')}${client.facebook}`}
                            className="fa fa-facebook no-underline hover:no-underline"
                            target="_blank" />

                          <Link to={`${getSocialUrl('twitter')}${client.twitter}`}
                            className="fa fa-twitter no-underline hover:no-underline"
                            target="_blank" />

                          <Link to={`${getSocialUrl('instagram')}${client.instagram}`}
                            className="fa fa-instagram no-underline hover:no-underline"
                            target="_blank" />

                          <Link to={`${getSocialUrl('tiktok')}${client.tiktok}`}
                            className="fa fa-tiktok no-underline hover:no-underline"
                            target="_blank">

                            <img src={Tiktok} alt="" className="w-4 h-4" />
                          </Link>

                          <Link to={client.website}
                            className="fa fa-globe no-underline hover:no-underline"
                            target="_blank" />


                        </div>

                        <div id='parent-info' className='mt-6 mb-2'>
                          <h5 className="font-sf-medium text-gray-900 text-center mb-0">Parent Info</h5>
                          <div className="flex flex-col w-full items-center ">
                            <small className="font-sf-regular text-gray-800 text-left text-sm capitalize">
                              {this.getFullName(client.parent_first_name, client.parent_last_name)}
                            </small>
                            <SmallText text={client.parent_contact_number} icon={faPhone} />
                            <SmallText text={this.getParentFullAddres(client)} icon={faLocationArrow} />
                            <SmallText text={client.email} icon={faEnvelope} id="email" small />
                          </div>
                        </div>

                        <div className="w-full md:w-3/4">
                          <ClientDetail label="High School" value={client.high_school} />

                          <ClientDetail label="Club" value={client.club} />

                          <ClientDetail
                            label="College"
                            value={client.college.data && client.college.data.attributes.name}
                          />
                          <ClientDetail
                            label="Sport"
                            value={findSportName(client.sport_id, sports).label}
                          />

                          <ClientDetail
                            label="Position"
                            value={client.position.map(
                              (position, index, arr) =>
                                `${[position]}${index !== arr.length - 1 ? ', ' : ''} `
                            )}
                          />

                          <ClientDetail label="Class" value={client.passing_year} />

                          <ClientDetail label="About" value={client.about} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {this.state.bookingGpsCoords ?
              <div
                className="flex flex-col w-full md:w-3/5 p-3 items-center bg-white shadow-md mt-2"
                style={{ borderRadius: 20 }}>
                <h5 className="text-black font-sf-medium">Location Info</h5>

                <div className="w-full">
                  <div id="mapBox" className="m-3">
                    <Map
                      google={this.props.google}
                      zoom={15}
                      style={{
                        width: '100%',
                        height: '250px',
                        borderRadius: 10,
                        position: 'relative',
                      }}
                      initialCenter={this.state.bookingGpsCoords}>
                      <Marker position={this.state.bookingGpsCoords} />
                    </Map>
                  </div>
                </div>
              </div> : ''
            }
          </div>
        ) : (
          <div className="flex  container min-h-screen justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(enhancer(BookingShow));
