import React, { Component, createRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { connect } from 'react-redux';
import { CalendarActions, UserActions, BookingsActions } from '../../../store/actions';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Loader } from '../Loader';
import { formatTime, notify } from '../../../utils/utilities';
import SideOver from './SideOver';
import { DayCalendar, MonthCalendar } from './index';

let connectProps = {
  ...CalendarActions,
  ...BookingsActions,
  ...UserActions,
};

let connectState = state => ({
  availabilities: state.Calendar.calendar,
  entry: state.Calendar.calendar.get('entry'),
  entriesCount: state.Calendar.calendar.get('entriesCount'),
  buttonLoading: state.User.meta.get('buttonLoading'),
  currentUser: state.User.current.get('currentUser'),
});

let enhancer = connect(connectState, connectProps);

var clickEvent = null;

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEvents: [],
      showModal: false,
      addModal: false,
      selectedDay: '',
      selectInfo: null,
      events: [],
      editEvent: false,
      currentMonth: null,
      selectedType: 'dayGridMonth',
      deletePressed: false,
      current: null,
      calendarLoader: false,
    };
    this.calendarRef = createRef();
  }

  componentDidMount() {
    let { fromNewFlow, changeButtonLoading } = this.props;
    changeButtonLoading(false);
    this.setState({ calendarLoader: true });
    if (!fromNewFlow) {
      const {
        match: { params },
      } = this.props;
      if (params.month) {
        this.setState({ currentMonth: params.month });
      } else {
      }
    }
  }

  componentWillReceiveProps(newProps) {
    let data = newProps.updatedEntries;
    this.setDataInCalendar(data);

    if (newProps.updatedEntries.length !== this.props.updatedEntries.length) {
      this.getEntries();
    }
  }

  formatDateAndTime = (time, date) => {
    let timeSplit = time.split('T');
    let dateSplit = date.split('T');
    return dateSplit[0] + 'T' + timeSplit[1];
  };

  getEventCount = dateP => {
    let { entriesCount } = this.props;
    let obj = entriesCount && entriesCount.find(({ date }) => date === dateP);
    return obj ? obj.count : null;
  };

  setDataInCalendar = data => {
    const dates = data.map((item, index, arr) => {
      return {
        title: '',
        eventCount: this.getEventCount(item.attributes.booking_date),
        start: this.formatDateAndTime(item.attributes.from_time, item.attributes.booking_date),
        end: this.formatDateAndTime(item.attributes.to_time, item.attributes.booking_date),
        extendedProps: item,
        color:
          item.attributes.booking_date > moment().subtract(1, 'days').format()
            ? item.attributes.entry_color
            : 'transparent',
      };
    });
    this.setState({ events: dates, calendarLoader: false });
  };

  getData = async currentMonth => {
    let { getCalendarEntries, availabilities } = this.props;
    await getCalendarEntries(currentMonth);
    let data = availabilities.toJS().entries;
    this.setDataInCalendar(data);
  };

  handleDateSelect = async selectInfo => {
    let { setCalendarView } = this.props;
    let date = moment(selectInfo.startStr);
    this.setState({ selectedType: 'timeGridDay' });
    this.calendarRef.current.getApi().changeView('timeGridDay', selectInfo.startStr);
    setCalendarView(true);
    await this.props.getCalendarEntriesByDate(date.format('YYYY-MM-DD'));
    let data = this.props.availabilities.toJS().slotsByDate;
    this.setDataInCalendar(data);
  };

  getEntries = () => {
    setTimeout(() => {
      this.getData(this.state.currentMonth);
    }, 1500);
  };

  handleMonthChange = date => {
    let { fromNewFlow, setCalendarView, history, setCurrentMonth } = this.props;
    let month = moment(date.startStr).add(15, 'days').format('YYYY-MM-DD'); // added 15 days to get the correct month neglecting the starting and ending month dates that are shown in the calendar
    setCurrentMonth(month);
    this.setState({ currentMonth: month, selectedType: date.view.type }, () => {
      if (!fromNewFlow) history.push(`/availability/${month}`);
    });
    setCalendarView(date.view.type === 'timeGridDay');
    if (date.view.type !== 'timeGridDay') {
      this.getData(month);
    }
  };

  closeModal = () => {
    this.setState({
      addModal: false,
    });
  };

  save = async data => {
    this.props.changeButtonLoading(true);
    let { editEvent, currentMonth } = this.state;
    const repetition_from = editEvent ? data.startDate : this.state.selectedDay;
    const from_time = data.start_time;
    const to_time = data.end_time;
    const repetition_frequency = data.frequency === 'do not repeat' ? 'daily' : data.frequency;
    const repetition_until = data.endDate;
    const repetition_count = data.repeatCount;
    const days = data.selectedDays;
    const dontRepeat = data.dontRepeat;

    const payload = {
      availability: {
        from_time,
        to_time,
        repetition_frequency,
        repetition_from,
        repetition_until,
        repetition_count: dontRepeat ? 1 : repetition_count,
        days,
        repetition_never_stop: data.neverStop,
      },
    };

    await this.props.createAvailability(payload);
    await this.getData(currentMonth);
    this.props.changeButtonLoading(false);
    this.closeModal();
  };

  deleteEvent = () => {
    this.setState({ showModal: false });
    this.state.clickEvent.remove();
    const eventData = this.state.clickEvent.extendedProps;
    this.props.deleteEvent(eventData.id);
    notify('Deleted successfully', 'success');
  };

  handleEventClick = ({ event, el, jsEvent, view }) => {
    clickEvent = event;
  };

  handleEvents = events => {
    this.setState({
      currentEvents: events,
    });
  };

  showPopup = () => {
    this.setState({ deletePressed: true });
  };

  deleteEntry = () => {
    const id = clickEvent.extendedProps.id;
    this.setState({ deletePressed: false, showModal: false }, async () => {
      await this.props.deleteEntry(id);
      this.getData(this.state.currentMonth);
    });
  };

  showDetails = id => {
    this.props.getEntry(id);
    setTimeout(() => {
      this.setState({ showModal: true });
    }, 100);
  };

  renderDayClass = arg => {
    return arg.isToday
      ? 'text-lg font-bold'
      : arg.isPast
      ? 'text-black text-lg opacity-50'
      : arg.isOther
      ? 'opacity-50 text-xl text-black'
      : 'text-lg font-bold text-black';
  };

  getTrainingType = colorType => {
    return colorType === 'virtual_color'
      ? 'Virtual'
      : colorType === 'group_color'
      ? 'Group'
      : 'Individual';
  };

  moveToDetail(id) {
    this.props.history.push({
      pathname: `/bookings/${id}`,
    });
  }

  renderEventContent = eventInfo => {
    let props = eventInfo.event.extendedProps;
    let sTime = null;
    let eTime = null;
    let { selectedType } = this.state;

    if (selectedType === 'timeGridDay') {
      return (
        <DayCalendar
          attributes={props.attributes}
          moveToDetail={id => this.moveToDetail(id)}
          getTrainingType={this.getTrainingType}
          key={props.attributes}
        />
      );
    } else {
      let calendarEntry = props.attributes;
      sTime = formatTime(props && props.attributes && props.attributes.from_time);
      eTime = formatTime(props && props.attributes && props.attributes.to_time);

      let day = moment(props.attributes.booking_date).format('D');
      if (document.getElementsByClassName(`${day}-day`)[0]) {
        document.getElementsByClassName(`${day}-day`)[0].textContent =
          (props.eventCount ? props.eventCount : '') +
          (props.eventCount > 1 ? ' events' : ' event');

        document.getElementsByClassName(`${day}-circle`)[0].style.height = '8px';
        document.getElementsByClassName(`${day}-circle`)[0].style.width = '8px';
      }

      return (
        <div>
          <div className="flex items-center">
            <div className={` ${day}-circle bg-blue-900 mr-1 rounded-full`} />
            <small className={`${day}-day primary-text-regular text-sm`} />
          </div>

          <MonthCalendar
            sTime={sTime}
            eTime={eTime}
            calendarEntry={calendarEntry}
            id={props.id}
            getTrainingType={this.getTrainingType}
            moveToDetail={id => this.moveToDetail(id)}
            showPopup={this.showPopup}
            showDetails={this.showDetails}
          />
        </div>
      );
    }
  };

  render() {
    let { showModal, events, editEvent, selectedType, calendarLoader } = this.state;
    let { buttonLoading, entry, currentUser } = this.props;

    return (
      <div>
        {!calendarLoader ? (
          <div>
            <div className="p-5 bg-white shadow-md day-highlight" style={{ borderRadius: 10 }}>
              <FullCalendar
                ref={this.calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
                headerToolbar={{
                  left: selectedType === 'dayGridMonth' ? 'prev,today,next' : 'prev,today,next',
                  center: 'title',
                  right:
                    selectedType === 'dayGridMonth'
                      ? 'dayGridMonth,dayGridWeek'
                      : 'dayGridMonth,dayGridWeek',
                }}
                initialView={'dayGridMonth'}
                timeZone={currentUser?.time_zone}
                editable={true}
                selectable={true}
                dayCellClassNames={this.renderDayClass}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                slotEventOverlap={true}
                showNonCurrentDates={true}
                allDaySlot={false}
                initialDate={this.state.currentMonth}
                datesSet={date => {
                  this.handleMonthChange(date);
                }}
                events={events}
                select={this.handleDateSelect}
                eventContent={this.renderEventContent} // custom render function
                eventClick={this.handleEventClick}
                eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              />
            </div>

            {showModal && (
              <SideOver
                showPopup={this.showPopup}
                hideSideOver={() => this.setState({ showModal: false })}
                clickEvent={clickEvent}
                save={this.save}
                update={this.save}
                closeModal={() => this.closeModal()}
                selectedDay={moment(this.state.selectedDay).format('YYYY-MM-DD')}
                editEvent={true}
                entry={entry}
                info={clickEvent.extendedProps}
                buttonLoading={buttonLoading}
                passUpdatedEntries={this.getEntries}
                trainings={this.props.trainings}
              />
            )}

            <SweetAlert
              show={this.state.deletePressed}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={this.deleteEntry}
              onCancel={() => {
                this.setState({ deletePressed: false });
              }}
              focusCancelBtn>
              You will not be able to recover!
            </SweetAlert>
          </div>
        ) : (
          <div className="flex  container min-h-screen justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default enhancer(Calendar);
