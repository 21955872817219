import React from 'react';
import { Link } from 'react-router-dom';
import { bookingStatus, paymentStatus } from '../../constants';

function PendingPaymentsBanner({ accountAdded, bookings }) {
  const isConfirmBooking = bookings.some(
    booking => (
      booking.attributes.status === bookingStatus.COMPLETED &&
      booking.attributes.payment.data.attributes.status === paymentStatus.PAID
    )
  );

  return isConfirmBooking ? (
    <div className="w-full flex text-white bg-success font-bold p-3 text-center mt-1 position-sticky top-0 z-10 justify-center">
      {accountAdded ? (
        <p className="mb-0">
          You have bookings to confirm in order to release your payment. Please go to your
          <Link to="/bookings" class="text-white mx-1 font-black underline">
            bookings
          </Link>
          and confirm them
        </p>
      ) : (
        <p className="mb-0">
          You have bookings to confirm in order to release your payment. Please first
          <a href="/card" class="text-white mx-1 font-black underline">
            click here
          </a>
          to add your bank account info, then go to your{' '}
          <Link to="/bookings" class="text-white mx-1 font-black underline">
            bookings
          </Link>{' '}
          and confirm them.{' '}
        </p>
      )}
    </div>
  ) : null;
}

export default PendingPaymentsBanner;
