import React, { Fragment, useState } from 'react';
import SelectDatepicker from 'react-select-datepicker';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { passingYear } from '../../../constants';
import { InputLabel, OnboardingAction, PrivacyText } from '../../Shared';
import {isValidContactNumber} from "../../../utils/utilities";

function AccountInfo({
  email,
  firstName,
  lastName,
  password,
  passwordConfirmation,
  handleOnChange,
  gender,
  history,
  phoneNumber,
  dob,
  classPassed,
}) {
  const currentYear = new Date().getFullYear();
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [emailRepeatError, setEmailRepeatError] = useState(false);
  const [userEmail, setEmail] = useState(email);
  const [repeat, setRepeat] = useState('');

  const [phone, setPhone] = useState({
    invalid: false,
    repeatInvalid: false,
    value: phoneNumber,
    repeat: ''
  })

  const onEmailChanged = (value) => {
    setEmail(value);
    checkEmails(value, repeat);
  }

  const onRepeatEmailChanged = (value) => {
    setRepeat(value);
    checkEmails(userEmail, value);
  }

  const checkEmails = (email, repeat) => {
    if (email === repeat) {
      setEmailRepeatError(false);
      handleOnChange('email', email);

    } else {
      setEmailRepeatError(true);
      handleOnChange('email', false);

    }
  }

  const onRepeatPhoneChanged = (value) => {
    checkPhones(phone.value, value);
  }

  const onPhoneChanged = (value) => {
    if (!isValidContactNumber(value)) {
      setPhone({ ...phone, value, invalid: true });
    } else {
      checkPhones(value, phone.repeat);
    }
  }

  const checkPhones = (value, repeat) => {
    const isValid = value === repeat
    setPhone({
      ...phone,
      invalid: false,
      repeatInvalid: !isValid,
      value, repeat
    });
    handleOnChange('phoneNumber', isValid ? value : '');
  }

  return (
    <Fragment>
      <div className="container-wrap">
        <div className="container-half md:pr-3">
          <div className="text-left">
            <InputLabel label="First Name" color="black" />

            <Form.Control
              type="text"
              className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
              onChange={e => handleOnChange('firstName', e.target.value)}
              value={firstName}
              required
              autoFocus
            />
          </div>
        </div>

        <div className="container-half">
          <div className="text-left ">
            <InputLabel label="Last Name" color="black" />

            <Form.Control
              type="text"
              className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
              onChange={e => handleOnChange('lastName', e.target.value)}
              value={lastName}
              required
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Email" color="black" />

          <Form.Control
            type="email"
            className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
            onChange={e => onEmailChanged(e.target.value)}
            value={userEmail}
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Confirm email" color="black" />
          {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
          <input style={{ display: 'none' }} type="text" name="Repeat" />
          <Form.Control
              type="text"
              autoComplete="false"
              name="Repeat"
              className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
              onChange={e => onRepeatEmailChanged(e.target.value)}
          />
          { emailRepeatError ?
            <InputLabel label="Emails do not match" color="red" /> : ''
          }
        </div>
      </div>

      <div className="mt-3">
        <div className="container-wrap">
          <div className="container-half md:pr-3">
            <div className="text-left w-full">
              <InputLabel label="Gender" color="black" />

              <Select
                className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black"
                placeholder="Select gender"
                value={gender}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={selectedOption => {
                  handleOnChange('gender', selectedOption);
                  handleOnChange('position', null);
                }}
                options={[
                  { value: 'female', label: 'Female' },
                  { value: 'male', label: 'Male' },
                ]}
              />
            </div>
          </div>

          <div className="container-half">
            <div className="w-full text-left">
              <InputLabel label="College Grad Year" color="black" />

              <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="primary-text-regular"
                  placeholder="Select class"
                  value={classPassed ? classPassed : { label: currentYear, value: currentYear }}
                  isSearchable={false}
                  onChange={selectedOption => handleOnChange('passingYear', selectedOption)}
                  options={passingYear}
                  maxMenuHeight={150}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="container-wrap">
          <div className="container-half md:pr-3">
            <div className="text-left w-full">
              <InputLabel label="Phone Number" color="black" />

              <InputMask
                  placeholder="(555) 555-5555"
                  style={{ outline: 0, fontFamily: 'SF-light' }}
                  className="font-sf-regular text-xs rounded input-mask form-control"
                  mask="+1 (999) 999-9999"
                  maskChar=" "
                  value={phone.value}
                  onChange={e => onPhoneChanged(e.target.value)}
              />
              {
                phone.invalid ?
                  <p className={'text-center'}>
                    <small className="font-sf-regular text-xs text-red-800">Invalid phone number</small>
                  </p> : ''
              }
            </div>
          </div>

          <div className="container-half">
            <div className="w-full text-left">
              <InputLabel label="Confirm Phone Number" color="black" />

              <InputMask
                  placeholder="(555) 555-5555"
                  style={{ outline: 0, fontFamily: 'SF-light' }}
                  className="font-sf-regular text-xs rounded input-mask form-control"
                  mask="+1 (999) 999-9999"
                  maskChar=" "
                  value={phone.repeat}
                  onChange={e => onRepeatPhoneChanged(e.target.value)}
              />
              { !phone.invalid && phone.repeatInvalid ?
                  <p className={'text-center'}>
                    <small className="font-sf-regular text-xs text-red-800">Numbers don't match</small>
                  </p> : ''
              }
            </div>
          </div>
        </div>

      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Birthdate" color="black" />

          <SelectDatepicker
            format="month/day/year"
            value={dob}
            onDateChange={date => {
              handleOnChange('dob', date);
            }}
            value={dob}
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(moment().subtract(14, 'years').calendar())}
            showLabels={false}
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Password" color="black" />

          <Form.Control
            minLength={7}
            type="password"
            className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
            onChange={e => handleOnChange('password', e.target.value)}
            value={password}
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Confirm password" color="black" />

          <Form.Control
            minLength={7}
            type="password"
            className="font-sf-regular text-xs"
            onChange={e => handleOnChange('passwordConfirmation', e.target.value)}
            value={passwordConfirmation}
            required
          />
        </div>
      </div>

      <label className="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox text-primary-color h-5 w-5 md:h-6 md:h-6"
          checked={privacyChecked}
          onClick={() => setPrivacyChecked(!privacyChecked)}
        />
        <PrivacyText className="text-left mx-3" />
      </label>

      <OnboardingAction
        disableAction={(!privacyChecked || emailRepeatError || phone.repeatInvalid)}
        navigateLogin={() => {
          history.push('/athlete/login');
        }}
      />
    </Fragment>
  );
}

export default AccountInfo;
